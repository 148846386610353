import React, { useContext, useState, useEffect, Fragment } from 'react';
import OperatorContext from './context';
import {
  Box,
  Grid,
  Tooltip,
  IconButton,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from '@mui/material';
import * as actions from './actions';
import * as mutations from 'graphql/mutations';
import { API, graphqlOperation } from 'aws-amplify';
import { NotificationManager } from 'react-notifications';
import translate from 'util/translate';
import { red, indigo, green, grey, blue } from '@mui/material/colors';

import Alert from 'components/Neo/Alert';
import { Backup, Save, Edit, Cancel, Delete } from '@mui/icons-material';

const styles = {
  boxStyle: {
    margin: 1,
    '&:hover': {
      backgroundColor: '#eeeeee',
    },
  },
  iconButtonStyle: {
    '&:disabled': {
      backgroundColor: 'grey',
    },
  },
};

const RegionItem = (props) => {
  const { state, dispatch } = useContext(OperatorContext);
  const { region, technologies, operatorId } = props;
  const [alertRemoveRegion, setAlertRemoveRegion] = useState(false);
  const [editing, setEditing] = useState(false);

  // o que está na caixa de seleção
  const [currentTechnology, setCurrentTechnology] = useState(region.technology);

  // o ultimo estado salvo
  const [savedTechnology, setSavedTechnology] = useState(region.technology);

  //   async function saveLineup(aDisabled) {
  //     try {
  //       dispatch({
  //         type: actions.LOADING,
  //         payload: true,
  //       });

  //       let operation = mutations.createLineup;
  //       if ('createdAt' in lineup) {
  //         operation = mutations.updateLineup;
  //       }
  //       const lineupInput = {
  //         id: lineup.id,
  //         lineupChannelId: lineup.channel.id,
  //         lineupContractId: lineup.contract.id,
  //         disabled: aDisabled,
  //       };

  //       const result = await API.graphql(
  //         graphqlOperation(operation, { input: lineupInput })
  //       );

  //       if (result.errors) {
  //         console.log(result.errors);
  //         throw new Error(result.errors[0].message);
  //       }

  //       NotificationManager.success(
  //         "Dados salvos com sucesso",
  //         null,
  //         3000
  //       );
  //     } catch (error) {
  //       console.log(error);
  //     } finally {
  //       dispatch({
  //         type: actions.LOADING,
  //         payload: false,
  //       });
  //     }
  //   }

  //   const handleToggle = async function (aEvent) {
  //     const l = Object.assign({}, currentLineup);
  //     l.disabled = !aEvent.target.checked;
  //     setCurrentLineup(l);
  //     saveLineup(!aEvent.target.checked);
  //   };

  async function onSave(aEvent) {
    try {
      dispatch({ type: actions.LOADING, payload: true });

      const regionInput = {
        id: region.id,
        technology: currentTechnology,
        operatorId: operatorId,
        regionOperatorId: operatorId,
        regionMunicipalityId: region.municipality.id,
        disabled: false,
      };

      await API.graphql(
        graphqlOperation(mutations.updateRegion, { input: regionInput })
      );

      setSavedTechnology(currentTechnology);
    } catch (error) {
      NotificationManager.error(error.toString(), null, 2000);
      console.log(error);
    } finally {
      dispatch({ type: actions.LOADING, payload: false });
    }
  }

  const onDeleteRegion = async function () {
    try {
      dispatch({
        type: actions.LOADING,
        payload: true,
      });

      const result = await API.graphql(
        graphqlOperation(mutations.deleteRegion, {
          input: {
            id: region.id,
          },
        })
      );

      NotificationManager.success('Dados removidos com sucesso', null, 3000);

      // remove do array em memória
      dispatch({
        type: actions.REMOVE_REGION,
        payload: region.id,
      });
    } catch (error) {
      console.log(error);
      NotificationManager.error(error.toString(), null, 3000);
    } finally {
      dispatch({
        type: actions.LOADING,
        payload: false,
      });

      setAlertRemoveRegion(false);
    }
  };

  return (
    <Fragment>
      <Alert
        show={alertRemoveRegion}
        onCancel={() => setAlertRemoveRegion(false)}
        onOk={onDeleteRegion}
        body={
          'Tem certeza? Isso removerá também as planilhas de base dessa praça!'
        }
        title={'Remover praça'}
      />

      <Box sx={styles.boxStyle}>
        <Grid container spacing={1}>
          <Grid item xs={1}>
            {region.municipality.state}
          </Grid>
          <Grid item xs={3}>
            {region.municipality.name}
          </Grid>
          <Grid item xs={2}>
            <FormControl className="w-100 mb-2" variant="standard">
              <InputLabel>Tecnologia</InputLabel>
              <Select
                name="region_technology"
                value={currentTechnology}
                onChange={(e) => setCurrentTechnology(e.target.value)}
                variant="standard"
                disabled={!editing}
              >
                {technologies.map((item, idx) => (
                  <MenuItem key={idx} value={item}>
                    {translate(item)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={1}>
            <Tooltip title="Editar">
              <span>
                <IconButton
                  sx={[styles.iconButton, { color: indigo[500] }]}
                  onClick={() => {
                    setEditing(true);
                  }}
                  size="small"
                  disabled={editing}
                >
                  <Edit />
                </IconButton>
              </span>
            </Tooltip>
          </Grid>
          <Grid item xs={1}>
            <Tooltip title="Cancelar">
              <span>
                <IconButton
                  sx={[styles.iconButton, { color: 'orange' }]}
                  onClick={() => {
                    setCurrentTechnology(savedTechnology);
                    setEditing(false);
                  }}
                  size="small"
                  disabled={!editing}
                >
                  <Cancel />
                </IconButton>
              </span>
            </Tooltip>
          </Grid>
          <Grid item xs={1}>
            <Tooltip title="Salvar">
              <span>
                <IconButton
                  sx={[styles.iconButton, { color: 'green' }]}
                  onClick={() => {
                    onSave();
                    setEditing(false);
                  }}
                  size="small"
                  disabled={!editing}
                >
                  <Save />
                </IconButton>
              </span>
            </Tooltip>
          </Grid>
          <Grid item xs={1}>
            <Tooltip title={'Remover praça'}>
              <span>
                <IconButton
                  sx={[styles.iconButton, { color: 'red' }]}
                  onClick={() => {
                    setAlertRemoveRegion(true);
                  }}
                  size="small"
                  disabled={Boolean(state.operator.disabled) || editing}
                >
                  <Delete />
                </IconButton>
              </span>
            </Tooltip>
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
};

export default RegionItem;
