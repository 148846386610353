import React, { useContext, useState, useEffect } from 'react';

import {
  Button,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Modal,
  Typography,
} from '@mui/material';
import { NotificationManager } from 'react-notifications';

import { v4 } from 'uuid';
import * as mutations from 'graphql/mutations';
import * as customGql from 'graphql/custom/functions';
import { API, graphqlOperation } from 'aws-amplify';
import translate from 'util/translate';

import OperatorContext from './context';
import * as actions from './actions';

const styleModal = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  flexDirection: 'column',
};

const styleButtonRow = {
  marginTop: 16,
  display: 'flex',
  flexDirection: 'row',
  columnGap: 12,
};

// hardcoded :(
// id dos 'municípios' DTH, Streaming e Serviços Digitais
const DTH_CITYCODE = '84d116f9-7b58-4112-babe-bd453b7e09a3';
const STREAMING_CITYCODE = '361e1362-77a2-4829-bd2a-7bd4362f5ca9';
const SERVICOS_DIGITAIS_CITYCODE = 'e20e9fdb-0248-4a75-8d5e-c25c5dd2e068';
const VOD_CITYCODE = '4044c3fa-f060-45ce-8081-eb198f4c7e28';

const AddRegion = (props) => {
  const { state, dispatch } = useContext(OperatorContext);

  const { technologies } = props;
  const [cities, setCities] = useState([]);
  const [selectedCities, setSelectedCities] = useState([]);
  const [technology, setTechnology] = useState('IPTV');
  const [uf, setUf] = useState('AL');

  function plain(str) {
    return str
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');
  }

  function compareState(a, b) {
    if (plain(a) > plain(b)) {
      return 1;
    }

    if (plain(a) < plain(b)) {
      return -1;
    }

    return 0;
  }

  function compareCity(a, b) {
    if (plain(a.name) > plain(b.name)) {
      return 1;
    }

    if (plain(a.name) < plain(b.name)) {
      return -1;
    }

    return 0;
  }

  useEffect(() => {
    return function cleanup() {};
  }, []);

  useEffect(() => {
    listCities();

    return function cleanup() {};
  }, [uf]);

  // usado para ter no máximo  uma praça com DTH, Streming e Serviços Digitais ou VOD (apenas uma de cada)
  function disableByTechnology(aTechnology) {
    const technologies = ['DTH, Streaming, servicos_digitais', 'VOD'];

    for (const technology of technologies) {
      state.operator.regions.items.forEach((item, i) => {
        if (item.technology === technology) {
          return true;
        }
      });
    }

    return false;
  }

  function disableCity(id) {
    let d = false;

    state.operator.regions.items.forEach((item, i) => {
      if (item.municipality.id === id) {
        if (Boolean(item.disabled) === false) {
          d = true;
        }
      }
    });

    return d;
  }

  const listCities = async function () {
    try {
      dispatch({ type: actions.LOADING, payload: true });

      if (
        technology === 'DTH' ||
        technology === 'Streaming' ||
        technology === 'servicos_digitais' ||
        technology === 'VOD'
      ) {
        return;
      }

      const result = await customGql.getCities(uf);
      if (result.errors) {
        console.log(result.errors);
        throw new Error(result.errors[0].message);
      }
      setCities(result.items);
    } catch (error) {
      console.log(error);
      NotificationManager.error(error.toString(), null, 2000);
    } finally {
      dispatch({ type: actions.LOADING, payload: false });
    }
  };

  function handleChangeCity(event) {
    const { options } = event.target;

    let s = [];
    Object.keys(options).forEach((item, i) => {
      if (options[item].selected) {
        s.push(options[item].value);
      }
    });
    setSelectedCities(s);
  }

  function handleChangeTechnology(event) {
    const newTechnology = event.target.value;

    setTechnology(newTechnology);

    if (newTechnology === 'DTH') {
      setUf('');
      setCities([]);
      setSelectedCities([
        DTH_CITYCODE, // codigo do "municipio" de DTH
      ]);
    }

    if (newTechnology === 'Streaming') {
      setUf('');
      setCities([]);
      setSelectedCities([
        STREAMING_CITYCODE, // codigo do "municipio" de Streaming
      ]);
    }

    if (newTechnology === 'servicos_digitais') {
      setUf('');
      setCities([]);
      setSelectedCities([
        SERVICOS_DIGITAIS_CITYCODE, // codigo do "municipio" de SERVICOS DIGITAIS
      ]);
    }

    if (newTechnology === 'VOD') {
      setUf('');
      setCities([]);
      setSelectedCities([
        VOD_CITYCODE, // codigo do "municipio" de VOD
      ]);
    }
  }

  function handleChangeState(aEvent) {
    setUf(aEvent.target.value);
  }

  async function onSave(aEvent) {
    try {
      dispatch({ type: actions.LOADING, payload: true });

      const promises = [];
      selectedCities.forEach((city, i) => {
        let regionId = v4();
        let operation = mutations.createRegion;

        // verifica antes se já existe a praça e está apenas desabilitada
        state.operator.regions.items.forEach((region, i) => {
          if (region.municipality.id === city) {
            regionId = region.id;
            operation = mutations.updateRegion;
          }
        });

        const regionInput = {
          id: regionId,
          technology: technology,
          operatorId: state.operator.id,
          regionOperatorId: state.operator.id,
          regionMunicipalityId: city,
          disabled: false,
        };

        promises.push(
          API.graphql(graphqlOperation(operation, { input: regionInput }))
        );
      });

      const results = await Promise.all(promises);

      let op = Object.assign({}, state.operator);
      results.forEach((result, i) => {
        const key = Object.keys(result.data).find((k) => k.includes('Region'));
        const r = result.data[key];

        // se já existia (mas estava desabilitada), remove antes do array
        // para não ficar duplicado
        let j;
        for (j = op.regions.items.length - 1; j >= 0; j--) {
          if (op.regions.items[j].id === r.id) {
            op.regions.items.splice(j, 1);
          }
        }

        op.regions.items.push(r);
      });

      dispatch({ type: actions.SET_OPERATOR, payload: op });
    } catch (error) {
      NotificationManager.error(error.toString(), null, 2000);
      console.log(error);
    } finally {
      dispatch({ type: actions.LOADING, payload: false });
      dispatch({ type: actions.MODAL_REGION, payload: false });
      setSelectedCities([]);
    }
  }

  const states = [
    'RO',
    'AC',
    'AM',
    'RR',
    'PA',
    'AP',
    'TO',
    'MA',
    'PI',
    'CE',
    'RN',
    'PB',
    'PE',
    'AL',
    'SE',
    'BA',
    'MG',
    'ES',
    'RJ',
    'SP',
    'PR',
    'SC',
    'RS',
    'MS',
    'MT',
    'GO',
    'DF',
  ];

  return (
    <Modal open={state.modalRegion}>
      <Box sx={styleModal}>
        <Box display="flex" flexDirection="column" minWidth={240}>
          <FormControl required variant="standard">
            <InputLabel>Tecnologia</InputLabel>
            <Select
              name="region_technology"
              value={technology}
              onChange={(e) => handleChangeTechnology(e)}
              variant="standard"
            >
              {technologies.map((item, idx) => (
                <MenuItem
                  key={idx}
                  value={item}
                  disabled={disableByTechnology(item)}
                >
                  {translate(item)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl
            disabled={
              technology === 'DTH' ||
              technology === 'Streaming' ||
              technology === 'servicos_digitais' ||
              technology === 'VOD'
            }
            variant="standard"
          >
            <InputLabel>Estado</InputLabel>
            <Select
              name="region_state"
              value={uf}
              onChange={(e) => handleChangeState(e)}
              variant="standard"
            >
              {states.sort(compareState).map((item, idx) => (
                <MenuItem key={idx} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box height={380} width="100%">
          <FormControl
            disabled={
              cities.length === 0 ||
              technology === 'DTH' ||
              technology === 'Streaming' ||
              technology === 'servicos_digitais' ||
              technology === 'VOD'
            }
            variant="standard"
          >
            <InputLabel shrink>Cidade</InputLabel>
            <Select
              native
              multiple
              inputProps={{
                id: 'select-multiple-native',
              }}
              name="region_municipality"
              onChange={(e) => handleChangeCity(e)}
              variant="standard"
            >
              {cities.sort(compareCity).map((item, idx) => (
                <option
                  key={idx}
                  value={item.id}
                  disabled={disableCity(item.id)}
                >
                  {item.name}
                </option>
              ))}
            </Select>
          </FormControl>
          <InputLabel>
            <Typography variant="caption" color="textSecondary">
              <i>Pressione CTRL para selecionar mais de uma cidade.</i>
            </Typography>
          </InputLabel>
        </Box>
        <Box sx={styleButtonRow}>
          <Button
            onClick={(event) => onSave(event)}
            variant="contained"
            color="primary"
            disabled={selectedCities.length === 0}
          >
            Salvar
          </Button>

          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              setSelectedCities([]);
              dispatch({ type: actions.MODAL_REGION, payload: false });
            }}
          >
            Cancelar
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default AddRegion;
