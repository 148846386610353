import React, { useEffect, useState, useRef, Fragment } from 'react';

import { Link } from 'react-router-dom';
import { Add } from '@mui/icons-material';
import {
  Fab,
  Tooltip,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Box,
} from '@mui/material';
import {
  NotificationManager,
  NotificationContainer,
} from 'react-notifications';
import Loading from 'util/loading';

import * as customGql from 'graphql/custom/functions';
import * as queries from 'graphql/custom/queries';
import { red, indigo, green, grey, blue } from '@mui/material/colors';

const styles = {
  styleBar: {
    backgroundColor: 'white',
    borderRadius: 2,
    marginBottom: 2,
    padding: 2,
    textAlign: 'center',
  },
  listBall: {
    padding: 1,
    borderRadius: 3,
    backgroundColor: indigo[500],
    backgroundSize: 'contain',
    width: 160,
    height: 60,
    textAlign: 'center',
    marginRight: 1,
    marginBottom: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: `white`,
  },
};

const OperatorListPage = () => {
  const [groupedOperators, setGroupedOperators] = useState({});
  const [loading, setLoading] = useState(false);
  const [includeDisabled, setIncludeDisabled] = useState(false);

  function plain(aStr) {
    return aStr
      .toString()
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');
  }

  function compare(a, b) {
    if (plain(a.trade) > plain(b.trade)) return 1;
    if (plain(b.trade) > plain(a.trade)) return -1;

    return 0;
  }

  function groupOperators(aOperators) {
    let groups = {};
    let currentLetter = '';
    for (const operator of aOperators.filter(disabledFilter).sort(compare)) {
      const firstLetter = plain(operator.trade).substr(0, 1);

      if (currentLetter !== firstLetter) {
        currentLetter = firstLetter;
        groups[currentLetter] = [];
      }

      groups[currentLetter].push(operator);
    }

    setGroupedOperators(groups);
  }

  const disabledFilter = function (aOperator) {
    if (includeDisabled) {
      return true;
    } else {
      return !Boolean(aOperator.disabled);
    }
  };

  const updateOperatorList = async function () {
    try {
      setLoading(true);
      const result = await customGql.listAll(queries.listOperators);
      groupOperators(result);
    } catch (error) {
      NotificationManager.error(error.toString(), null, 3000);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    updateOperatorList();
  }, [includeDisabled]);

  return (
    <div className="app-wrapper">
      <Loading loading={loading} />
      <NotificationContainer />

      {/* 
          -------------------------
          BARRA SUPERIOR 
          ------------------------- 
      */}
      <Grid container sx={styles.styleBar}>
        <Grid item xs={11}>
          <h2>Operadoras</h2>
        </Grid>
        <Grid item xs={1}>
          <Tooltip title={'Adicionar operadora'}>
            <Link to="/operator/000">
              <Fab
                size="small"
                sx={{
                  color: 'white',
                  backgroundColor: blue[500],
                  marginLeft: 'auto',
                }}
              >
                <Add />
              </Fab>
            </Link>
          </Tooltip>
        </Grid>
        <Grid item xs={8}>
          &nbsp;
        </Grid>
        <Grid item xs={4}>
          <FormGroup>
            <FormControlLabel
              sx={{ justifyContent: 'right' }}
              control={
                <Checkbox
                  checked={includeDisabled}
                  onChange={(e) => setIncludeDisabled(e.target.checked)}
                />
              }
              label="Incluir desabilitadas"
            />
          </FormGroup>
        </Grid>
      </Grid>

      {/* 
          -------------------------
          Lista de operadoras
          ------------------------- 
      */}
      {Object.keys(groupedOperators).map((letter, idx) => (
        <Box
          key={letter}
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Fab
            size="small"
            sx={{
              color: 'white',
              margin: 1,
              backgroundColor: indigo[500],
              marginLeft: 0,
            }}
          >
            {letter.toUpperCase()}
          </Fab>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
            }}
          >
            {groupedOperators[letter].map((operator, idx2) => (
              <Box key={operator.id} sx={styles.listBall}>
                <Link
                  to={`/operator/${operator.id}`}
                  style={{ color: 'white' }}
                >
                  {operator.trade}
                </Link>
              </Box>
            ))}
          </Box>
        </Box>
      ))}
    </div>
  );
};

export default OperatorListPage;
