import React, { useContext, useState, useEffect, useRef } from 'react';
import OperatorContext from './context';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Box,
  TextField,
  IconButton,
  Button,
  Tooltip,
  FormControlLabel,
  Switch,
} from '@mui/material';
import { Backup, Delete, Save, Add } from '@mui/icons-material';
import { red, indigo, green, grey, blue } from '@mui/material/colors';

import * as actions from './actions';
import * as mutations from 'graphql/mutations';
import * as queries from 'graphql/queries';
import { API, graphqlOperation } from 'aws-amplify';
import { NotificationManager } from 'react-notifications';
import { width } from '@mui/system';

const XLSX = require('xlsx');
const styles = {
  lineupBox: {
    display: 'flex',
    flexDirection: 'column',
    border: 1,
    borderRadius: 1,
    marginTop: 1,
    padding: 1,
  },
  addGrowthPlans: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    marginBottom: 2,
  },
  containerLine: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    gap: '10px 10px',
    marginTop: 1,
    marginBottom: 1,
  },
  delete: {
    justifySelf: 'flex-end',
  },
};

const GrowthPlans = (props) => {
  const { id, onDelete, growthplan } = props;
  const [listSva, setListSva] = useState([]);
  const { state, dispatch } = useContext(OperatorContext);
  const [growthPlanTable, setGrowthPlanTable] = useState(growthplan);
  const inputFile = useRef(null);

  const layout = {
    colunaMes: 'A',
    colunaPC: 'B',
    linhaInicial: 2,
  };

  useEffect(() => {
    listSvas();
  }, []);

  function handleToggleCombo(aEvent) {
    let op = Object.assign({}, growthPlanTable);
    op.combo = aEvent.target.checked;
    setGrowthPlanTable(op);
  }

  function handleToggleDisabled(aEvent) {
    let gpTable = Object.assign({}, growthPlanTable);
    gpTable.disabled = aEvent.target.checked;
    const dt = new Date();
    gpTable.disabledDate = dt.toISOString();

    setGrowthPlanTable(gpTable);
  }

  const listSvas = async function () {
    try {
      dispatch({
        type: actions.LOADING,
        payload: true,
      });

      const b = await API.graphql(
        graphqlOperation(queries.listSVAs, { limit: 200 })
      );
      setListSva(b.data.listSVAs.items);
    } catch (error) {
      NotificationManager.error(error.toString(), null, 2000);
      console.log(error);
    } finally {
      dispatch({
        type: actions.LOADING,
        payload: false,
      });
    }
  };

  const parseSheet = async function (aData) {
    let currentLine = layout.linhaInicial - 1;
    let blankCounter = 0;

    const workBook = XLSX.read(aData, {
      type: 'buffer',
    });
    const worksheetNames = workBook.SheetNames;
    // the exit criteria is 20 blank lines (channel column) in sequence

    // faz o parse das planilhas
    for (const wName of worksheetNames) {
      const workSheet = workBook.Sheets[wName];
      while (blankCounter < 20) {
        currentLine++;

        const mesCell = layout.colunaMes + currentLine.toString();
        const PCCell = layout.colunaPC + currentLine.toString();

        if (!(mesCell in workSheet)) {
          blankCounter++;
          continue;
        }

        blankCounter = 0;

        const serial = workSheet[mesCell].v;
        const pc = workSheet[PCCell].v;
        const utc_days = Math.floor(serial - 25569);
        const utc_value = utc_days * 86400;
        const date_info = new Date(utc_value * 1000);

        const month = date_info.getUTCMonth() + 1; // do 1 ao 12
        const year = date_info.getUTCFullYear();
        addLine(pc, month, year);
      }
    }
  };

  const uploadSheet = async () => {
    // `current` points to the mounted file input element
    inputFile.current.value = null;
    inputFile.current.onchange = () => {
      try {
        dispatch({
          type: actions.LOADING,
          payload: true,
        });
        const sheetFile = inputFile.current.files[0];
        const reader = new FileReader();

        reader.onload = async (e) => {
          await parseSheet(e.target.result);

          //loadSpreadsheets();
        };
        reader.readAsArrayBuffer(sheetFile);
      } catch (error) {
        console.log(error);
        NotificationManager.error(error.toString(), null, 3000);
      } finally {
        dispatch({
          type: actions.LOADING,
          payload: false,
        });
      }
    };

    inputFile.current.click();
  };

  const handleChangeMonth = (event, index) => {
    const b = Object.assign({}, growthPlanTable);
    b.planTable[index].month = parseInt(event.target.value);
    setGrowthPlanTable(b);
  };
  const handleChangeYear = (event, index) => {
    const b = Object.assign({}, growthPlanTable);
    b.planTable[index].year = parseInt(event.target.value);
    setGrowthPlanTable(b);
  };
  const handleChangeUserBase = (event, index) => {
    const b = Object.assign({}, growthPlanTable);
    b.planTable[index].userbase = parseInt(event.target.value);
    setGrowthPlanTable(b);
  };
  const handleChangeSva = (event) => {
    const b = Object.assign({}, growthPlanTable);
    b.svaId = event.target.value;
    setGrowthPlanTable(b);
  };
  const onDeleteLine = (index) => {
    let c = Object.assign({}, growthPlanTable);
    c.planTable.splice(index, 1);
    setGrowthPlanTable(c);
  };

  const addLine = (aUserBase = 0, aMonth = 0, aYear = 0) => {
    const b = Object.assign({}, growthPlanTable);
    b.planTable.push({
      userbase: aUserBase,
      month: aMonth,
      year: aYear,
    });
    setGrowthPlanTable(b);
  };

  function comparePlanTable(a, b) {
    if (a.year > b.year) return 1;
    if (b.year > a.year) return -1;
    if (a.month > b.month) return 1;
    if (b.month > a.month) return -1;

    return 0;
  }

  const salva = async () => {
    try {
      dispatch({
        type: actions.LOADING,
        payload: true,
      });

      // TODO verificar se não tem outro com o mesmo nome antes

      const growthPlansInput = {
        combo: growthPlanTable.combo,
        disabled: growthPlanTable.disabled,
        disabledDate: growthPlanTable.disabledDate || '',
        svaId: growthPlanTable.svaId,
        id: id,
        growthPlanSvaId: growthPlanTable.svaId,
        growthPlanOperatorId: state.operator.id,
        operatorId: state.operator.id,
        planTable: growthPlanTable.planTable.sort(comparePlanTable),
      };

      let operation;
      if (!('createdAt' in growthplan)) {
        operation = mutations.createGrowthPlan;
      } else {
        operation = mutations.updateGrowthPlan;
      }

      const result = await API.graphql(
        graphqlOperation(operation, {
          input: growthPlansInput,
        })
      );
    } catch (error) {
      console.log(error);
      let msg = error.toString();
      if ('errors' in error) {
        msg = error.errors[0].message;
      }
      NotificationManager.error(msg, null, 3000);
    } finally {
      dispatch({
        type: actions.LOADING,
        payload: false,
      });
    }
  };

  function plain(str) {
    return str
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');
  }

  function compareOperator(a, b) {
    if (plain(a.name) > plain(b.name)) {
      return 1;
    }

    if (plain(a.name) < plain(b.name)) {
      return -1;
    }

    return 0;
  }

  function pad(aValue) {
    let s = '00' + aValue;
    return s.substring(s.length - 2);
  }

  return (
    <Box sx={styles.lineupBox}>
      <input
        type="file"
        id="file"
        ref={inputFile}
        style={{ display: 'none' }}
        accept=".xls,.xlsx"
      />
      <Box sx={styles.addGrowthPlans}>
        <FormControl>
          <InputLabel id="sva-simple-select-label">SVA</InputLabel>

          <Select
            labelId="sva-simple-select-label"
            id="sva-simple-select"
            sx={{
              width: 180,
            }}
            value={growthPlanTable.svaId}
            label="Sva"
            onChange={handleChangeSva}
          >
            <MenuItem value={''} disabled selected>
              selecione sva
            </MenuItem>
            {listSva.sort(compareOperator).map((item, idx) => {
              return (
                <MenuItem key={idx} value={item.id}>
                  {item.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <FormControl>
          <FormControlLabel
            control={
              <Switch
                color="primary"
                onClick={(event) => handleToggleCombo(event)}
                checked={Boolean(growthPlanTable.combo)}
              />
            }
            label="combo"
          />
        </FormControl>
        <FormControl>
          <FormControlLabel
            className="ml-1"
            control={
              <Switch
                color="primary"
                onClick={(event) => handleToggleDisabled(event)}
                checked={Boolean(growthPlanTable.disabled)}
              />
            }
            label="desabilitar"
          />
        </FormControl>

        <Tooltip title="Acrescentar linha">
          <Box>
            <IconButton
              onClick={addLine}
              disabled={growthPlanTable.svaId.length == 0}
              sx={{
                color: 'white',
                backgroundColor: blue[500],
                marginLeft: 'auto',
                height: 'fit-content',
              }}
            >
              <Add />
            </IconButton>
          </Box>
        </Tooltip>

        <Tooltip title="Subir tabela">
          <Box>
            <IconButton
              onClick={(e) => uploadSheet()}
              disabled={growthPlanTable.svaId.length == 0}
              sx={{
                color: 'white',
                backgroundColor: blue[500],
                marginLeft: 'auto',
                height: 'fit-content',
              }}
            >
              <Backup />
            </IconButton>
          </Box>
        </Tooltip>

        <Tooltip title="Salvar">
          <Box>
            <IconButton
              onClick={salva}
              edge="end"
              sx={{
                color: 'white',
                backgroundColor: green[500],
                marginLeft: 'auto',
                height: 'fit-content',
              }}
            >
              <Save />
            </IconButton>
          </Box>
        </Tooltip>

        <Tooltip title="Remover plano">
          <Box>
            <IconButton
              onClick={() => onDelete(id)}
              sx={{
                color: 'white',
                backgroundColor: red[500],
                marginLeft: 'auto',
                height: 'fit-content',
              }}
            >
              <Delete />
            </IconButton>
          </Box>
        </Tooltip>
      </Box>

      {growthPlanTable.planTable.map((item, index) => {
        return (
          <Box sx={styles.containerLine} key={index}>
            <FormControl>
              <InputLabel id="month-simple-select-label">Mês</InputLabel>
              <Select
                labelId="month-simple-select-label"
                id="month-simple-select"
                label="Mês"
                value={pad(item.month)}
                onChange={(event) => handleChangeMonth(event, index)}
              >
                <MenuItem value={''} disabled selected>
                  selecione mês
                </MenuItem>
                <MenuItem value={'01'}>Janeiro</MenuItem>
                <MenuItem value={'02'}>Fevereiro</MenuItem>
                <MenuItem value={'03'}>Março</MenuItem>
                <MenuItem value={'04'}>Abril</MenuItem>
                <MenuItem value={'05'}>Maio</MenuItem>
                <MenuItem value={'06'}>Junho</MenuItem>
                <MenuItem value={'07'}>Julho</MenuItem>
                <MenuItem value={'08'}>Agosto</MenuItem>
                <MenuItem value={'09'}>Setembro</MenuItem>
                <MenuItem value={'10'}>Outubro</MenuItem>
                <MenuItem value={'11'}>Novembro</MenuItem>
                <MenuItem value={'12'}>Dezembro</MenuItem>
              </Select>
            </FormControl>
            <TextField
              label="ano"
              onInput={(e) => {
                e.target.value = Math.max(0, parseInt(e.target.value))
                  .toString()
                  .slice(0, 4);
              }}
              type="number"
              value={item.year}
              variant="standard"
              onChange={(event) => handleChangeYear(event, index)}
              required
            />
            <TextField
              label="assinaturas"
              type="number"
              value={item.userbase}
              variant="standard"
              onChange={(event) => handleChangeUserBase(event, index)}
              required
            />

            <Tooltip title="Remover linha">
              <Box>
                <IconButton
                  onClick={() => onDeleteLine(index)}
                  sx={{
                    color: 'white',
                    backgroundColor: red[500],
                    marginLeft: 'auto',
                    height: 'fit-content',
                  }}
                >
                  <Delete />
                </IconButton>
              </Box>
            </Tooltip>
          </Box>
        );
      })}
    </Box>
  );
};

export default GrowthPlans;
