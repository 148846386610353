import React, { useContext, useEffect, useState, Fragment } from 'react';
import OperatorContext from './context';
import * as queries from 'graphql/queries';
import {
  Modal,
  InputLabel,
  Button,
  Select,
  MenuItem,
  Box,
} from '@mui/material';
import * as actions from './actions';
import * as customGql from 'graphql/custom/functions';

import { v4 } from 'uuid';
import { NotificationManager } from 'react-notifications';
import * as mutations from 'graphql/mutations';
import { API, graphqlOperation } from 'aws-amplify';

const styleModal = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 1,
  display: 'flex',
  flexDirection: 'column',
};

const styleButtonRow = {
  marginTop: 1,
  display: 'flex',
  flexDirection: 'row',
  gap: 1,
};

const ModalBroadcaster = () => {
  const { state, dispatch } = useContext(OperatorContext);
  const [broadcasters, setBroadcasters] = useState([]);
  const [selectedBroadcaster, setSelectedBroadcaster] = useState('');

  function plain(str) {
    return str
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');
  }

  function compare(a, b) {
    if (plain(a.trade) > plain(b.trade)) {
      return 1;
    }

    if (plain(a.trade) < plain(b.trade)) {
      return -1;
    }

    return 0;
  }

  // filtra só as programadoras que ainda não tem
  const myFilter = function (aItem) {
    let notFound = true;
    for (const contract of state.operator.contracts.items) {
      if (aItem.id === contract.broadcaster.id) {
        notFound = false;
      }
    }

    return notFound;
  };

  const listBroadcasters = async function () {
    try {
      dispatch({ type: actions.LOADING, payload: true });
      const allBroadcasters = await customGql.listAll(queries.listBroadcasters);
      setBroadcasters(allBroadcasters.filter(myFilter));
    } catch (error) {
      console.log(error);
      NotificationManager.error(error.toString(), null, 3000);
    } finally {
      dispatch({ type: actions.LOADING, payload: false });
    }
  };

  useEffect(() => {
    if (state.modalBroadcaster) {
      listBroadcasters();
    }

    return function cleanup() {};
  }, [state.modalBroadcaster]);

  const handleChangeBroadcaster = async function (aEvent) {
    console.log(aEvent.target.value);
    setSelectedBroadcaster(aEvent.target.value);
  };

  const saveContract = async function (aId) {
    try {
      dispatch({ type: actions.LOADING, payload: true });

      const contractInput = {
        id: aId,
        broadcasterId: selectedBroadcaster,
        contractBroadcasterId: selectedBroadcaster,
        operatorId: state.operator.id,
        contractOperatorId: state.operator.id,
      };

      const operation = mutations.createContract;
      const result = await API.graphql(
        graphqlOperation(operation, { input: contractInput })
      );

      if (result.errors) {
        console.log(result.errors);
        throw new Error(result.errors[0].message);
      }

      NotificationManager.success('Dados salvos com sucesso', null, 3000);
    } catch (error) {
      console.log(error);
      NotificationManager.error(error.toString(), null, 3000);
    } finally {
      dispatch({ type: actions.LOADING, payload: false });
    }
  };

  const onSelect = async function (aEvent) {
    console.log('clicou');
    let operatorCopy = Object.assign({}, state.operator);

    let broadcaster;
    for (const b of broadcasters) {
      if (b.id === selectedBroadcaster) {
        broadcaster = b;
      }
    }

    const id = v4();
    await saveContract(id);

    operatorCopy.contracts.items.push({
      id: id,
      broadcaster: broadcaster,
    });

    dispatch({
      type: actions.SET_OPERATOR,
      payload: operatorCopy,
    });

    dispatch({
      type: actions.MODAL_BROADCASTER,
      payload: false,
    });
  };

  return (
    <Modal open={state.modalBroadcaster}>
      <Box sx={styleModal}>
        'Adicionar contrato'
        <InputLabel>'Parceiro'</InputLabel>
        <Select
          name="broadcasterId"
          value={selectedBroadcaster}
          onChange={handleChangeBroadcaster}
          variant="standard"
        >
          {broadcasters.sort(compare).map((item, idx) => (
            <MenuItem key={idx} value={item.id}>
              {item.trade}
            </MenuItem>
          ))}
        </Select>
        <Box sx={styleButtonRow}>
          <Button
            variant="contained"
            color="primary"
            onClick={(event) => onSelect(event)}
          >
            Selecionar
          </Button>

          <Button
            variant="contained"
            color="secondary"
            className="ml-auto"
            onClick={() => {
              dispatch({
                type: actions.MODAL_BROADCASTER,
                payload: false,
              });
            }}
          >
            Cancelar
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
export default ModalBroadcaster;
