import React from 'react';
import { Route, Routes } from 'react-router-dom';

import HomePage from 'app/routes/HomePage';
import Reports2Page from 'app/routes/Reports2';
import ParserPage from 'app/routes/Parser';
import WarningPage from 'app/routes/Warnings';
import UserbasePage from 'app/routes/UserBase';
import BroadcasterListPage from 'app/routes/Broadcaster/List';
import BroadcasterPage from 'app/routes/Broadcaster/Item';
import OperatorListPage from 'app/routes/Operator/List';
import OperatorPage from 'app/routes/Operator/Item';
import Error404 from 'components/Error404';

const MainRoutes = () => (
  <Routes>
    <Route exact path="/" element={<HomePage />} />
    <Route path="/operatorlist" element={<OperatorListPage />} />
    <Route path="/operator/:id" element={<OperatorPage />} />
    <Route path="/broadcasterlist" element={<BroadcasterListPage />} />
    <Route path="/broadcaster/:id" element={<BroadcasterPage />} />
    <Route path="/userbase" element={<UserbasePage />} />
    <Route path="/reports2" element={<Reports2Page />} />
    <Route path="/parser" element={<ParserPage />} />
    <Route path="/warnings" element={<WarningPage />} />

    {/* 404 must be the last one */}
    <Route element={Error404} />
  </Routes>
);

export default MainRoutes;
