import React from 'react';
import preval from 'preval.macro';
import { Box, Paper, Typography } from '@mui/material';

import { red, indigo, green, grey, blue } from '@mui/material/colors';

const HomePage = () => {
  const build = preval`module.exports = new Date().toLocaleString('pt-BR', {timeZone: 'America/Sao_Paulo'});`;

  return (
    <Paper
      elevation={3}
      sx={{
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        width: '100%',
        height: '80vh',
        backgroundColor: 'white',
        position: 'relative',
      }}
    >
      <Box
        component="img"
        sx={{
          width: 320,
          height: 320,
          backgroundImage: "url('/images/welcome.jpg')",
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
        }}
      />

      <Typography>
        <b>Neo Reports</b>
        <br />
        Build Date: {build}
        <br />
        {/* Commit version: {gitInfo.head}
        <br />
        <i>{gitInfo.staged}</i> */}
      </Typography>
    </Paper>
  );
};

export default HomePage;
