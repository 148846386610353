import React, { useEffect, useState, useRef, Fragment } from 'react';

import {
  Card,
  CardContent,
  CardHeader,
  CardActions,
  FormControl,
  InputLabel,
  Box,
  Select,
  Alert,
  Snackbar,
  MenuItem,
  Avatar,
} from '@mui/material';
import { v4 } from 'uuid';
import { Computer, Delete } from '@mui/icons-material';
import Loading from 'util/loading';
import { API, Auth, graphqlOperation } from 'aws-amplify';

import * as customQueries from 'graphql/custom/queries';
import * as customGql from 'graphql/custom/functions';

import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from '@mui/x-data-grid';

function initialDate() {
  let dt = new Date();
  dt.setDate(10);
  dt.setMonth(dt.getMonth() - 1);
  return dt;
}

const filterPromisesResult = function (aResult) {
  return aResult.status === 'fulfilled';
};

const columns = [
  { field: 'sheet', headerName: 'Aba', width: 120 },
  { field: 'city', headerName: 'Praça', width: 300 },
  {
    field: 'warningType',
    headerName: 'Tipo',
    width: 200,
    renderCell: (params) => <span>{params.value}</span>,
  },
  { field: 'warning', headerName: 'Mensagem', width: 600 },
];

const styles = {
  topBox: {
    columnGap: 12,
    // '&:hover': {
    //   backgroundColor: '#eaeaea',
    // },
  },
};

const WarningItem = (props) => {
  const [alert, setAlert] = useState({
    severity: 'info',
    message: '',
    open: false,
  });

  // data selecionada
  const iDate = initialDate();
  const [refDate, setRefDate] = useState(iDate);

  const [loading, setLoading] = useState(false);

  const [operators, setOperators] = useState([]);
  const [selectedOperator, setSelectedOperator] = useState(0);

  const [tableData, setTableData] = useState([]);

  const CustomToolbar = function () {
    let operatorName = '';
    for (const operator of operators) {
      if (selectedOperator === operator.id) {
        operatorName = operator.trade;
      }
    }
    return (
      <GridToolbarContainer>
        <GridToolbarExport
          csvOptions={{
            fileName: `warningTable ${operatorName} ${
              refDate.getMonth() + 1
            }-${refDate.getFullYear()}`,
            utf8WithBom: true,
          }}
        />{' '}
      </GridToolbarContainer>
    );
  };

  // inicial - pega a lista de programadoras
  useEffect(() => {
    updateOperatorsList();

    return function cleanup() {};
  }, []);

  const translateWT = function (aWarningType) {
    switch (aWarningType) {
      case 'DATE_MISMATCH': {
        return 'Data';
      }

      case 'ISP_MISMATCH': {
        return 'Operador';
      }

      case 'CITY_MISMATCH': {
        return 'Praça';
      }

      case 'BROADCASTER_NOT': {
        return 'Praça';
      }

      case 'VARIATION': {
        return 'Variação alta';
      }

      case 'LINEUP_ZERO': {
        return 'Base=0, \u2208 Lineup';
      }

      case 'NOT_IN_LINEUP': {
        return 'Base>0, \u2209 Lineup';
      }

      case 'INITIAL_BASE_ZERO': {
        return 'Base Inicial 0';
      }
      case 'EMPTY_PACKAGE': {
        return 'Sem pacote';
      }

      case 'OTHER': {
        return 'Outros';
      }

      case 'BASE_ZERO': {
        return 'Base=0';
      }
      case 'BASE_PRODUCT_ZERO': {
        return 'Base Produto=0';
      }
      case 'NOT_IN_SVA': {
        return 'Base>0, \u2209 SVA';
      }

      default:
        return aWarningType;
    }
  };

  function compare(a, b) {
    if (a.trade > b.trade) return 1;
    if (b.trade > a.trade) return -1;

    return 0;
  }

  const updateOperatorsList = async function () {
    try {
      setLoading(true);
      const result = await customGql.listAll(customQueries.listOperators);
      setOperators(result.sort(compare));
    } catch (error) {
      setAlert({
        message: error.toString(),
        severity: 'error',
        open: true,
      });
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    retrieveWarnings();

    return function cleanup() {};
  }, [refDate, selectedOperator]);

  const retrieveWarnings = async function () {
    const wrnTable = [];

    try {
      setLoading(true);

      // monta o parâmetro period no formato 2022-12
      const m = (refDate.getMonth() + 1).toString().padStart(2, '0');
      const y = refDate.getFullYear().toString();
      const period = y + '-' + m;

      let nextToken = null;

      do {
        const options = {
          operatorId: selectedOperator,
          period: { eq: period },
          nextToken: nextToken,
          limit: 1000,
        };

        const data = (
          await API.graphql(
            graphqlOperation(customQueries.warningByOperator, options)
          )
        ).data.warningByOperator;

        nextToken = data.nextToken;

        for (const w of data.items) {
          const line = {
            sheet: w.sheet || ' ',
            city: `${w.region.municipality.name}/${w.region.municipality.state}`,
            warning: w.warning,
            warningType: translateWT(w.warningType),
          };
          wrnTable.push(line);
        }
      } while (nextToken !== null);
    } catch (error) {
      setAlert({
        message: error.toString(),
        severity: 'error',
        open: true,
      });
      console.log(error);
    } finally {
      setLoading(false);
      setTableData(wrnTable);
    }
  };

  // quando usuário clica no botão para mudar o mês
  function handleChangeMonth(event) {
    const m = event.target.value;
    const d = new Date(refDate);
    d.setMonth(m);
    setRefDate(d);
  }

  // quando usuário clica no botão para mudar o ano
  function handleChangeYear(event) {
    const y = event.target.value;
    const d = new Date(refDate);
    d.setFullYear(y);
    setRefDate(d);
  }

  const handleChangeOperator = function (aEvent) {
    setSelectedOperator(aEvent.target.value);
  };

  // quando usuário clica no botão para fechar o alerta
  function handleCloseAlert(event) {
    setAlert({
      message: '',
      severity: 'info',
      open: false,
    });
  }

  // retorna os anos a partir de 2020
  function getYearsList() {
    const years = [];
    for (let i = new Date().getFullYear(); i >= 2020; i--) {
      years.push(i);
    }
    return years;
  }

  return (
    <Fragment>
      <Loading loading={Boolean(loading)} />

      <Snackbar
        open={alert.open}
        autoHideDuration={4000}
        onClose={(e) => handleCloseAlert(e)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Alert severity={alert.severity}>{alert.message}</Alert>
      </Snackbar>

      <Card className="shadow border-0">
        <CardContent>
          <Box display="flex" flexDirection="row" sx={styles.topBox}>
            <FormControl className="w-100" variant="standard">
              <InputLabel>Mês</InputLabel>
              <Select
                name="month"
                value={refDate.getMonth()}
                onChange={(e) => handleChangeMonth(e)}
                variant="standard"
                sx={{
                  width: 180,
                }}
              >
                {[...Array(12).keys()].map((item, idx) => (
                  <MenuItem key={idx} value={idx}>
                    {(idx + 1).toString().padStart(2, '0')}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className="w-100" variant="standard">
              <InputLabel>Ano</InputLabel>
              <Select
                name="year"
                value={refDate.getFullYear()}
                onChange={(e) => handleChangeYear(e)}
                variant="standard"
                sx={{
                  width: 180,
                }}
              >
                {getYearsList().map((item, idx) => (
                  <MenuItem key={idx} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className="w-100" variant="standard">
              <InputLabel>Operadora</InputLabel>
              <Select
                name="operator"
                value={selectedOperator}
                onChange={(e) => handleChangeOperator(e)}
                variant="standard"
                sx={{
                  width: 180,
                }}
              >
                {operators.map((item, idx) => (
                  <MenuItem key={idx} value={item.id}>
                    {item.trade}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </CardContent>
      </Card>

      <Card>
        <CardContent
          sx={{
            height: 550,
          }}
        >
          <DataGrid
            rows={tableData}
            columns={columns}
            getRowId={(row) => v4()}
            initialState={{
              sorting: {
                sortModel: [{ field: 'city', sort: 'asc' }],
              },
            }}
            components={{
              Toolbar: CustomToolbar,
            }}
          />
          ;
        </CardContent>
      </Card>
    </Fragment>
  );
};

export default WarningItem;
