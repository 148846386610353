import React, {
  useContext,
  useRef,
  useEffect,
  Fragment,
  useState,
} from 'react';
import axios from 'axios';
import { Backup, GetApp, Delete, Map, LocationCity } from '@mui/icons-material';
import {
  Box,
  Fab,
  Typography,
  Chip,
  Avatar,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  CircularProgress,
  BottomNavigation,
  BottomNavigationAction,
  Drawer,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Paper,
  Badge,
} from '@mui/material';

import { red, indigo, green, grey, blue } from '@mui/material/colors';

import * as customQueries from 'graphql/custom/queries';
import * as customGql from 'graphql/custom/functions';
import * as mutations from 'graphql/mutations';
import { API, graphqlOperation, Storage } from 'aws-amplify';
import {
  NotificationManager,
  NotificationContainer,
} from 'react-notifications';
import Loading from 'util/loading';
import Alert from 'components/Neo/Alert';

import Context from './context';
import * as actions from './actions';

const Item = () => {
  const mounted = useRef(true);
  const [loading, setLoading] = useState(false);

  const [includeDisabled, setIncludeDisabled] = useState(false);
  const { state, dispatch } = useContext(Context);
  const [showReportsCheckedInput, setShowReportsCheckedInput] = useState(false);
  const [years, setYears] = useState([]);

  const inputFile = useRef(null);

  // popula inicialmente o campo dos anos
  useEffect(() => {
    const y = [];
    if (years.length === 0) {
      let now = new Date();
      for (let i = now.getFullYear(); i >= 2020; i--) {
        y.push(i);
      }
      setYears(y);
    }
  }, []);

  // receivedMessages
  // When ParseSheet (backend side) finishes, it sends a message so we can update the warnings
  useEffect(() => {
    function getLastMessage() {
      let message = null;
      if (state.receivedMessages.length > 0) {
        let idx = state.receivedMessages.length - 1;
        message = JSON.parse(state.receivedMessages[idx].message);
      }
      return message;
    }

    let message = getLastMessage();

    if (
      message &&
      message.hasOwnProperty('id') &&
      message.id === state.currentOperator.id
    ) {
      let m = (state.refDate.getMonth() + 1).toString().padStart(2, '0');
      let y = state.refDate.getFullYear().toString();
      let period = y + '-' + m;

      if (message.hasOwnProperty('period') && message.period === period) {
        if (message.hasOwnProperty('region')) {
          let regionId = message.region;
          let prefix =
            y + '/' + m + '/' + state.currentOperator.id + '/' + regionId + '/';

          setLoading(true);

          Storage.list(prefix, {
            level: 'public',
          })
            .then((result) => {
              let w = Object.assign({}, state.sheetWarnings);

              if (w[regionId]) {
                delete w[regionId];
              }

              // todos arquivos do operador, para aquele período
              result.results.forEach(function (s3file) {
                let keys = s3file.key.split('/');
                let o = keys[2]; // operator
                let r = keys[3]; // region
                let f = keys[4]; // fileName

                if (f === 'warning.txt') {
                  w[r] = s3file.key;
                }
              });

              dispatch({
                type: actions.SET_SHEET_WARNINGS,
                payload: w,
              });
              setLoading(false);
            })
            .catch((error) => {
              setLoading(false);
              NotificationManager.error('Erro de leitura', null, 3000);
            });
        }
      }
    }

    return function cleanup() {
      //    mounted.current = false;
    };
  }, [state.receivedMessages]);

  function plain(str) {
    return str
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');
  }

  function toggleDrawer(region) {
    if (region === null) {
      region = {
        id: '',
        municipality: {
          state: '',
          name: '',
        },
      };
    }

    return function () {
      dispatch({
        type: actions.SET_CURRENT_REGION,
        payload: region,
      });
    };
  }

  function compareRegion(a, b) {
    if (state.bottomNavValue === 0) {
      if (plain(a.municipality.state) > plain(b.municipality.state)) {
        return 1;
      }

      if (plain(a.municipality.state) < plain(b.municipality.state)) {
        return -1;
      }
    }

    if (plain(a.municipality.name) > plain(b.municipality.name)) {
      return 1;
    }

    if (plain(a.municipality.name) < plain(b.municipality.name)) {
      return -1;
    }

    return 0;
  }

  function compareOperator(a, b) {
    if (plain(a.trade) > plain(b.trade)) {
      return 1;
    }

    if (plain(a.trade) < plain(b.trade)) {
      return -1;
    }

    return 0;
  }

  async function handleChangeOperator(event) {
    if (mounted.current) {
      let id = event.target.value;
      setLoading(true);
      let op = await customGql.getOperator(id);
      dispatch({
        type: actions.SET_CURRENT_OPERATOR,
        payload: op,
      });
      setLoading(false);
    }
  }

  function handleChangeMonth(event) {
    if (mounted.current) {
      let m = event.target.value;
      let d = new Date(state.refDate);
      d.setMonth(m);

      dispatch({
        type: actions.SET_REFDATE,
        payload: d,
      });
    }
  }

  function handleChangeYear(event) {
    if (mounted.current) {
      let y = event.target.value;
      let d = new Date(state.refDate);
      d.setFullYear(y);
      dispatch({
        type: actions.SET_REFDATE,
        payload: d,
      });
    }
  }

  function loadSpreadsheets() {
    if (mounted.current === true) {
      setLoading(true);
    }

    let m = (state.refDate.getMonth() + 1).toString().padStart(2, '0');
    let y = state.refDate.getFullYear().toString();
    let prefix = y + '/' + m + '/' + state.currentOperator.id + '/';
    Storage.list(prefix, {
      level: 'public',
    })
      .then((result) => {
        if (mounted.current === false) {
          return;
        }

        let s = [];
        let w = [];

        // todos arquivos do operador, para aquele período
        result.results.forEach(function (s3file) {
          let keys = s3file.key.split('/');
          let y = keys[0]; // year
          let m = keys[1]; // month
          let o = keys[2]; // operator
          let r = keys[3]; // region
          let f = keys[4]; // fileName

          if (f === 'warning.txt') {
            w[r] = s3file.key;
          } else {
            if (s[o] == null) {
              s[o] = [];
            }
            s[o][r] = {
              fileName: f,
              lastModified: s3file.lastModified,
            };
          }
        });
        dispatch({
          type: actions.SET_SHEETS,
          payload: s,
        });

        dispatch({
          type: actions.SET_SHEET_WARNINGS,
          payload: w,
        });

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (mounted.current === false) {
          return;
        }
        setLoading(false);

        NotificationManager.error('Erro de leitura', null, 3000);
      });
  }

  // initial
  useEffect(() => {
    async function listOperators() {
      try {
        setLoading(true);

        const result = await customGql.listAll(customQueries.listOperators);

        dispatch({
          type: actions.SET_OPERATORS,
          payload: result,
        });
      } catch (error) {
        console.log(error);

        if (mounted.current === false) {
          return;
        }
        NotificationManager.error(error.toString(), null, 3000);
      } finally {
        setLoading(false);
      }
    }

    listOperators();

    return function cleanup() {
      mounted.current = false;
    };
  }, []);

  // refDate, currentOperator
  useEffect(() => {
    loadSpreadsheets();
    let dt = new Date();
    const month = dt.getMonth();
    const year = dt.getFullYear();
    if (
      (state.refDate.getMonth() === month - 1 &&
        state.refDate.getFullYear() === year) ||
      (state.refDate.getFullYear() === year - 1 &&
        Boolean(state.currentOperator.id))
    ) {
      setShowReportsCheckedInput(true);
    } else {
      setShowReportsCheckedInput(false);
    }
  }, [state.refDate, state.currentOperator]);

  // refDate, currentRegion
  useEffect(() => {
    if (
      state.currentRegion.id.length > 0 &&
      state.sheetWarnings[state.currentRegion.id]
    ) {
      setLoading(true);

      let key = state.sheetWarnings[state.currentRegion.id];
      Storage.get(key, {
        level: 'public',
        expires: 60,
        cacheControl: 'no-cache',
      })
        .then((result) => {
          axios
            .get(result)
            .then((res) => {
              dispatch({
                type: actions.SET_CURRENT_WARNING,
                payload: res.data,
              });
              setLoading(false);
            })
            .catch((error) => {
              dispatch({
                type: actions.SET_CURRENT_WARNING,
                payload: null,
              });
              setLoading(false);
              console.log(error);
            });
        })
        .catch((error) => {
          dispatch({
            type: actions.SET_CURRENT_WARNING,
            payload: null,
          });
          setLoading(false);

          console.log(error);
        });
    } else {
      dispatch({
        type: actions.SET_CURRENT_WARNING,
        payload: null,
      });
    }
  }, [state.refDate, state.currentRegion]);

  const handleReportsChecked = async function (aEvent) {
    try {
      setLoading(true);

      // Quando o relatório foi conferido, o usuário externo fica desabilitado para subir/apagar as planilhas daquele mês
      let op = Object.assign({}, state.currentOperator);
      op.externalDisabled = aEvent.target.checked;
      const operatorInput = {
        company_name: state.currentOperator.company_name,
        corporate_registry: state.currentOperator.corporate_registry,
        trade: state.currentOperator.trade,
        id: state.currentOperator.id,
        externalDisabled: op.externalDisabled,
      };

      const result = await API.graphql(
        graphqlOperation(mutations.updateOperator, {
          input: operatorInput,
        })
      );
      dispatch({
        type: actions.SET_CURRENT_OPERATOR,
        payload: op,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const uploadSheet = async (aOperator, aRegion) => {
    /*
      File name structure
      YYYY/MM/op_id/reg_id/name
      YYYY   - year
      MM     - month (01 to 12)
      op_id  - operator id
      reg_id - region_id
      name   - original file name
    */

    // ainda não sei pq, mas às vezes o region vem vazio.
    // acredito que é quando clica rápido e não deu tempo de
    // atualizar a variável. problema assíncrono. TODO
    if (!Boolean(aOperator) || !Boolean(aRegion)) {
      return;
    }

    // `current` points to the mounted file input element
    inputFile.current.value = null;
    inputFile.current.onchange = () => {
      try {
        setLoading(true);

        const sheetFile = inputFile.current.files[0];
        const reader = new FileReader();
        dispatch({
          type: actions.SET_UPLOAD_PROGRESS,
          payload: 0,
        });

        reader.onload = async (e) => {
          const m = (state.refDate.getMonth() + 1).toString().padStart(2, '0');
          const y = state.refDate.getFullYear().toString();
          const name =
            y +
            '/' +
            m +
            '/' +
            aOperator +
            '/' +
            aRegion +
            '/' +
            sheetFile.name;

          const result = await Storage.put(name, e.target.result, {
            cacheControl: 'no-cache',
            level: 'public',
            progressCallback(progress) {
              dispatch({
                type: actions.SET_UPLOAD_PROGRESS,
                payload: (100 * progress.loaded) / progress.total,
              });
            },
          });

          dispatch({
            type: actions.SET_UPLOAD_PROGRESS,
            payload: 0,
          });

          NotificationManager.success('Arquivo enviado', null, 3000);

          loadSpreadsheets();
        };
        reader.readAsArrayBuffer(sheetFile);
      } catch (error) {
        console.log(error);
        NotificationManager.error(error.toString(), null, 3000);
      } finally {
        setLoading(false);
      }
    };

    inputFile.current.click();
  };

  async function downloadSheet(operator, region) {
    try {
      setLoading(true);

      // pega a chave (nome completo, com caminho) do arquivo no bucket S3
      const m = (state.refDate.getMonth() + 1).toString().padStart(2, '0');
      const y = state.refDate.getFullYear().toString();
      const prefix = y + '/' + m + '/';
      const fileName = state.sheets[operator][region].fileName;
      const key = prefix + operator + '/' + region + '/' + fileName;

      // pega a URL de download temporário
      const result = await Storage.get(key, {
        level: 'public',
        expires: 60,
        download: true,
      });
      const url = URL.createObjectURL(result.Body);
      // abre a janela de download
      let dwnld = document.createElement('a');
      dwnld.setAttribute('href', url);
      dwnld.setAttribute('download', fileName);
      document.body.appendChild(dwnld);

      const evt = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true,
        clientX: 20,
        /* whatever properties you want to give it */
      });
      dwnld.dispatchEvent(evt);
      document.body.removeChild(dwnld);
    } catch (error) {
      console.log(error);
      NotificationManager.error(error.toString(), null, 3000);
    } finally {
      setLoading(false);
    }
  }

  function onHideDeleteSheetDialog() {
    let a = Object.assign({}, state.alert);
    a.dialog = false;
    dispatch({
      type: actions.SET_ALERT,
      payload: a,
    });
  }

  // retorna apenas as que não estão habilitadas,
  // a não ser que a opção de "incluir desabilitadas"
  // esteja selecionada
  function disabledFilter(item) {
    if (includeDisabled) {
      return true;
    } else {
      return !Boolean(item.disabled);
    }
  }

  /**
   * Muda a ordenação das praças (por Estado/Cidade ou por Cidade)
   */
  function handleChangeSortingFunc(event, value) {
    dispatch({
      type: actions.SET_BOTTOMNAVVALUE,
      payload: value,
    });
  }

  function onDeleteSheet() {
    let a = Object.assign({}, state.alert);
    a.dialog = false;
    dispatch({
      type: actions.SET_ALERT,
      payload: a,
    });
    setLoading(true);

    let m = (state.refDate.getMonth() + 1).toString().padStart(2, '0');
    let y = state.refDate.getFullYear().toString();
    let prefix =
      y + '/' + m + '/' + state.alert.operator + '/' + state.alert.region + '/';
    function remove(key) {
      return new Promise(function (resolve, reject) {
        Storage.remove(key, { level: 'public' })
          .then((result) => resolve(result))
          .catch((error) => reject(error));
      });
    }

    Storage.list(prefix, {
      level: 'public',
    })
      .then((result) => {
        let promises = [];
        result.results.forEach(function (file) {
          promises.push(remove(file.key));
        });

        Promise.all(promises)
          .then((result) => {
            let s = Object.assign({}, state.sheets);
            delete s[state.alert.operator][state.alert.region];
            dispatch({
              type: actions.SET_SHEETS,
              payload: s,
            });

            let w = Object.assign({}, state.sheetWarnings);
            delete w[state.alert.region];
            dispatch({
              type: actions.SET_SHEET_WARNINGS,
              payload: w,
            });
            setLoading(false);

            NotificationManager.success(
              'Dados removidos com sucesso',
              null,
              3000
            );
          })
          .catch((error) => {
            console.log(error);
            setLoading(false);
            NotificationManager.error('Erro de leitura', null, 3000);
          });
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        NotificationManager.error('Erro de leitura', null, 3000);
      });
  }

  function enabledFilter(item) {
    return !Boolean(item.disabled);
  }

  function handleIncludeDisabled(aEvent) {
    setIncludeDisabled(aEvent.target.checked);
  }

  return (
    <Fragment>
      <Loading loading={loading} />
      <NotificationContainer />

      <Alert
        show={state.alert.dialog}
        onCancel={onHideDeleteSheetDialog}
        onOk={onDeleteSheet}
        body={'Tem certeza?'}
        title={'Apagar arquivo'}
      />

      <input
        type="file"
        id="file"
        ref={inputFile}
        style={{ display: 'none' }}
        accept=".xls,.xlsx"
      />

      <Box justifyContent="flex-start" display="flex" className="mb-4">
        <Box flexGrow={1} className="pr-4">
          <FormControl sx={{ width: 130 }} variant="standard">
            <InputLabel>Operador</InputLabel>
            <Select
              name="operator"
              value={state.currentOperator.id}
              onChange={(e) => handleChangeOperator(e)}
              variant="standard"
            >
              {state.operators
                .filter(disabledFilter)
                .sort(compareOperator)
                .map((item, idx) => (
                  <MenuItem key={idx} value={item.id}>
                    {item.trade}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Box>

        <Box flexGrow={1} className="pr-4">
          <FormControl className="w-100" variant="standard">
            <InputLabel>Mês</InputLabel>
            <Select
              name="month"
              value={state.refDate.getMonth()}
              onChange={(e) => handleChangeMonth(e)}
              variant="standard"
            >
              {[...Array(12).keys()].map((item, idx) => (
                <MenuItem key={idx} value={idx}>
                  {(idx + 1).toString().padStart(2, '0')}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <Box flexGrow={1} className="pr-4">
          <FormControl className="w-100" variant="standard">
            <InputLabel>Ano</InputLabel>
            <Select
              name="month"
              value={state.refDate.getFullYear()}
              onChange={(e) => handleChangeYear(e)}
              variant="standard"
            >
              {years.map((item, idx) => (
                <MenuItem key={idx} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        {showReportsCheckedInput && (
          <Box flexGrow={1} className="pr-4">
            <FormGroup>
              <FormControlLabel
                sx={{ justifyContent: 'right' }}
                control={
                  <Checkbox
                    checked={Boolean(state.currentOperator.externalDisabled)}
                    onChange={(e) => handleReportsChecked(e)}
                  />
                }
                label="relatórios conferidos"
              />
            </FormGroup>
          </Box>
        )}

        <Box flexGrow={1} className="pr-4">
          <FormGroup>
            <FormControlLabel
              sx={{ justifyContent: 'right' }}
              control={
                <Checkbox
                  checked={includeDisabled}
                  onChange={(e) => handleIncludeDisabled(e)}
                />
              }
              label="Incluir desabilitadas"
            />
          </FormGroup>
        </Box>

        <Box flexGrow={1} className="pr-4">
          {state.uploadProgress > 0 && (
            <Box position="relative" display="inline-flex">
              <CircularProgress
                variant="determinate"
                value={state.uploadProgress}
              />
              <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Typography
                  variant="caption"
                  component="div"
                  color="textSecondary"
                >
                  {`${Math.round(state.uploadProgress)}%`}
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
      </Box>

      {state.currentOperator.regions.items.length > 0 && (
        <Box>
          <Drawer
            anchor="right"
            open={state.currentRegion.id.length > 0}
            onClose={toggleDrawer(null)}
          >
            <div
              tabIndex={0}
              role="button"
              onClick={toggleDrawer(null)}
              onKeyDown={toggleDrawer(null)}
              className="full-drawer p-3"
            >
              <List>
                <ListItem dense={true}>
                  <ListItemIcon>
                    <LocationCity />
                  </ListItemIcon>

                  <ListItemText
                    primary={
                      state.currentRegion.municipality.name +
                      ' / ' +
                      state.currentRegion.municipality.state
                    }
                  />
                </ListItem>
                <Divider />

                {state.sheets[state.currentOperator.id] &&
                  state.sheets[state.currentOperator.id][
                    state.currentRegion.id
                  ] && (
                    <Fragment>
                      <ListItem
                        button
                        onClick={(e) =>
                          downloadSheet(
                            state.currentOperator.id,
                            state.currentRegion.id
                          )
                        }
                      >
                        <ListItemIcon>
                          <GetApp />
                        </ListItemIcon>

                        <ListItemText
                          disableTypography
                          primary={
                            <Typography
                              variant="subtitle2"
                              color="textSecondary"
                            >
                              {
                                state.sheets[state.currentOperator.id][
                                  state.currentRegion.id
                                ].fileName
                              }
                            </Typography>
                          }
                          secondary={
                            <Typography variant="caption" color="textSecondary">
                              {state.sheets[state.currentOperator.id][
                                state.currentRegion.id
                              ].lastModified.toLocaleString()}
                            </Typography>
                          }
                        />
                      </ListItem>

                      <Divider />

                      <ListItem>
                        <Fab
                          size="small"
                          sx={{
                            color: 'white',
                            backgroundColor: red[500],
                            marginLeft: 'auto',
                          }}
                          onClick={(e) => {
                            let a = Object.assign({}, state.alert);
                            a.dialog = true;
                            a.operator = state.currentOperator.id;
                            a.region = state.currentRegion.id;
                            dispatch({
                              type: actions.SET_ALERT,
                              payload: a,
                            });
                          }}
                        >
                          <Delete />
                        </Fab>
                      </ListItem>
                      <Divider />
                    </Fragment>
                  )}

                {!(
                  state.sheets[state.currentOperator.id] &&
                  state.sheets[state.currentOperator.id][state.currentRegion.id]
                ) && (
                  <ListItem>
                    <Fab
                      size="small"
                      sx={{
                        color: 'white',
                        backgroundColor: indigo[500],
                        marginLeft: 'auto',
                      }}
                      onClick={(e) =>
                        uploadSheet(
                          state.currentOperator.id,
                          state.currentRegion.id
                        )
                      }
                    >
                      <Backup />
                    </Fab>
                  </ListItem>
                )}

                {state.currentWarning && (
                  <ListItem>
                    <ListItemText
                      disableTypography
                      primary={
                        <div className="logViewCard">
                          <Typography variant="caption" color="textSecondary">
                            {state.currentWarning}
                          </Typography>
                        </div>
                      }
                    />
                  </ListItem>
                )}
              </List>
            </div>
          </Drawer>

          <BottomNavigation
            value={state.bottomNavValue}
            showLabels
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              padding: 1,
              margin: 1,
            }}
            onChange={handleChangeSortingFunc}
          >
            <BottomNavigationAction label="Estado/Cidade" icon={<Map />} />
            <BottomNavigationAction label="Cidade" icon={<LocationCity />} />
          </BottomNavigation>

          <Paper
            elevation={3}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              gap: 1,
              width: '100%',
              minHeight: 200,
              margin: 2,
              padding: 2,
            }}
          >
            {state.currentOperator.regions.items
              .filter(enabledFilter)
              .sort(compareRegion)
              .map((region, reIdx) => {
                const hasSheet =
                  state.sheets[state.currentOperator.id] &&
                  state.sheets[state.currentOperator.id][region.id];
                return (
                  <Box key={reIdx + 'div'}>
                    <Badge
                      variant="dot"
                      color="error"
                      invisible={!Boolean(state.sheetWarnings[region.id])}
                    >
                      <Chip
                        avatar={<Avatar>{region.municipality.state}</Avatar>}
                        label={region.municipality.name}
                        size="small"
                        sx={{
                          color: hasSheet ? 'white' : grey[500],
                          backgroundColor: hasSheet ? indigo[500] : grey[50],
                          marginLeft: 'auto',
                        }}
                        onClick={toggleDrawer(region)}
                      />
                    </Badge>
                  </Box>
                );
              })}
          </Paper>
        </Box>
      )}

      {state.currentOperator.regions.items.length === 0 && (
        <Paper
          elevation={3}
          sx={{
            margin: 2,
            padding: 2,
          }}
        >
          <InputLabel>'Sem dados para exibição'</InputLabel>
        </Paper>
      )}
    </Fragment>
  );
};

export default Item;
