export default function translate(aString) {
  if (aString === 'SD_exclusive') {
    return 'SD Exclusivo';
  }

  if (aString === 'HD_exclusive') {
    return 'HD Exclusivo';
  }

  if (aString === 'HD_correspondent') {
    return 'HD Correspondente';
  }

  if (aString === 'IPTV') {
    return 'IPTV';
  }

  if (aString === 'digital_cable') {
    return 'Cabo digital';
  }

  if (aString === 'analog_cable') {
    return 'Cabo analógico';
  }

  if (aString === 'Streaming') {
    return 'Streaming';
  }

  if (aString === 'DTH') {
    return 'DTH';
  }

  return aString;
}
