import React, { useReducer } from 'react';
import Context from './context';
import Reducer from './reducer';

function initialDate() {
  let dt = new Date();
  dt.setDate(10);
  dt.setMonth(dt.getMonth() - 1);
  //dt.setMonth(dt.getMonth());
  return dt;
}

const INIT_STATE = {
  loading: 0,
  operators: [],
  currentOperator: {
    id: '',
    company_name: '',
    trade: '',
    corporate_registry: '',
    regions: {
      items: [],
    },
    contracts: {
      items: [],
    },
    disabled: false,
  },
  uploadProgress: 0,
  sheetWarnings: {},
  bottomNavValue: 0,
  alert: {
    dialog: false,
    operator: '',
    region: '',
  },
  sheets: [],
  refDate: initialDate(),
  currentRegion: {
    id: '',
    municipality: {
      state: '',
      name: '',
    },
  },
  currentWarning: null,
  receivedMessages: [],
  subscription: null,
  disponibleRegions: [],
  showLogBatchUpload: false,
  selectedFiles: [],
  batchLog: [],
};

const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(Reducer, INIT_STATE);

  return (
    <Context.Provider value={{ state, dispatch }}>{children}</Context.Provider>
  );
};

export default Provider;
