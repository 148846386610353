import React, { useState, useEffect } from 'react';
import { Typography, Box } from '@mui/material';
import { red, indigo, green, grey, blue } from '@mui/material/colors';
import { Auth } from 'aws-amplify';

const Footer = () => {
  const [username, setUsername] = useState('');

  const getUsername = async () => {
    const user = await Auth.currentAuthenticatedUser({
      bypassCache: true,
    });

    setUsername(user.username);
  };

  useEffect(() => {
    getUsername();
    return function cleanup() {};
  }, []);

  return (
    <Box
      sx={{
        backgroundColor: indigo[300],
        color: 'white',
        height: 20,
        bottom: 0,
        position: 'sticky',
        zIndex: 99999,
        paddingLeft: 1,
      }}
    >
      <Typography variant="caption">{username}</Typography>
    </Box>
  );
};
export default Footer;
