import React, { Fragment, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Menu, MenuItem, Button } from '@mui/material';

const myMenu = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (aEvent) => {
    setAnchorEl(aEvent.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Fragment>
      <Button id={'cadastro'} sx={{ color: 'white' }} onClick={handleClick}>
        Cadastro
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl) && anchorEl.id === 'cadastro'}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose}>
          <NavLink to="/operatorlist">Operadores</NavLink>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <NavLink to="/broadcasterlist">Parceiros</NavLink>
        </MenuItem>
      </Menu>

      <Button id={'base'} sx={{ color: 'white' }} onClick={handleClick}>
        Base de Clientes
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl) && anchorEl.id === 'base'}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose}>
          <NavLink to="/userbase">Operadores</NavLink>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <NavLink to="/reports2">Relatórios</NavLink>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <NavLink to="/parser">Processar Planilhas</NavLink>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <NavLink to="/warnings">Alarmes</NavLink>
        </MenuItem>
      </Menu>
    </Fragment>
  );
};

export default myMenu;
