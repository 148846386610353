import React, {
  useContext,
  useState,
  useEffect,
  useRef,
  Fragment,
} from 'react';
import OperatorContext from './context';
import {
  FormControlLabel,
  FormControl,
  Switch,
  Box,
  TextField,
  InputAdornment,
  IconButton,
  Tooltip,
} from '@mui/material';
import { Delete, Save, Edit, Cancel } from '@mui/icons-material';

import * as actions from './actions';
import * as mutations from 'graphql/mutations';
import { API, graphqlOperation } from 'aws-amplify';
import { NotificationManager } from 'react-notifications';

const styles = {
  lineupBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    '&:hover': {
      backgroundColor: '#eeeeee',
    },
  },
  filler: {
    minWidth: 80,
  },
};

const LineupItem = (props) => {
  const { state, dispatch } = useContext(OperatorContext);
  const { channel, lineup } = props;
  const [currentLineup, setCurrentLineup] = useState(lineup);
  // const initialRender = useRef(true);
  const [editing, setEditing] = useState(false);

  const [penetration, setPenetration] = useState(
    parseInt(lineup.penetration) || 0
  );
  const [lineupDisabled, setLineupDisabled] = useState(
    Boolean(lineup.disabled)
  );

  // useEffect(() => {
  //   if (initialRender.current) {
  //     initialRender.current = false;
  //   } else {
  //     saveLineup();
  //   }
  // }, [currentLineup]);

  const onSave = async function (aEvent) {
    try {
      dispatch({
        type: actions.LOADING,
        payload: true,
      });

      let operation = mutations.createLineup;
      if ('createdAt' in lineup) {
        operation = mutations.updateLineup;
      }
      const lineupInput = {
        id: lineup.id,
        channelId: lineup.channel.id,
        contractId: lineup.contract.id,
        lineupChannelId: lineup.channel.id,
        lineupContractId: lineup.contract.id,
        disabled: lineupDisabled,
        penetration: penetration,
      };

      const result = await API.graphql(
        graphqlOperation(operation, { input: lineupInput })
      );

      if (result.errors) {
        console.log(result.errors);
        throw new Error(result.errors[0].message);
      }

      NotificationManager.success('Dados salvos com sucesso', null, 3000);
    } catch (error) {
      console.log(error);
    } finally {
      dispatch({
        type: actions.LOADING,
        payload: false,
      });
      setEditing(false);
    }
  };

  const onChangeDisabled = async function (aEvent) {
    setLineupDisabled(!aEvent.target.checked);
  };

  const onChangePenetration = function (aEvent) {
    const value = Math.max(0, Math.min(aEvent.target.value, 100));
    setPenetration(value);
  };

  return (
    <Box sx={styles.lineupBox}>
      <FormControl variant="standard" sx={{ flexGrow: 2 }}>
        <FormControlLabel
          control={
            <Switch
              color="primary"
              onClick={(event) => onChangeDisabled(event)}
              checked={!Boolean(lineupDisabled)}
              disabled={
                Boolean(state.operator.disabled) ||
                Boolean(channel.disabled) ||
                Boolean(lineup.contract.broadcaster.disabled) ||
                !editing
              }
            />
          }
          label={channel.name}
        />
      </FormControl>

      <FormControl variant="standard">
        <TextField
          size="small"
          label="Penetração (%)"
          value={penetration}
          variant="standard"
          onChange={(event) => onChangePenetration(event)}
          disabled={
            Boolean(state.operator.disabled) ||
            Boolean(channel.disabled) ||
            Boolean(lineup.contract.broadcaster.disabled) ||
            !editing
          }
          inputProps={{
            style: { fontSize: 'small' },
            inputMode: 'numeric',
            pattern: '[0-9]*',
          }}
          InputLabelProps={{ style: { fontSize: 'small' } }}
        />
      </FormControl>

      {!editing && (
        <Tooltip title="Editar">
          <IconButton
            onClick={() => {
              setEditing(true);
            }}
          >
            <Edit />
          </IconButton>
        </Tooltip>
      )}

      {editing && (
        <Tooltip title="Salvar">
          <IconButton onClick={(event) => onSave(event)}>
            <Save />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  );
};

export default LineupItem;
