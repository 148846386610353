import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import { red, indigo, green, grey, blue } from '@mui/material/colors';

import Button from '@mui/material/Button';

const Alert = (props) => {
  const { show, title, onCancel, onOk, body } = props;

  return (
    <Dialog open={!!show}>
      <Box className="alert-dialog">
        <DialogTitle
          size="small"
          sx={{
            color: 'white',
            backgroundColor: blue[500],
            marginLeft: 'auto',
          }}
        >
          {title}
        </DialogTitle>

        <DialogContent>
          <DialogContentText>{body}</DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={onCancel}
            variant="contained"
            color="secondary"
            className="text-white"
          >
            {'Cancelar'}
          </Button>
          <Button
            onClick={onOk}
            variant="contained"
            color="primary"
            className="text-white ml-auto"
          >
            OK
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default Alert;
