import React, { useReducer } from 'react';
import BroadcasterContext from './context';
import BroadcasterReducer from './reducer';
import { v4 } from 'uuid';

const INIT_STATE = {
  broadcaster: {
    id: v4(),
    company_name: '',
    trade: '',
    corporate_registry: '',
    layout: 'NENHUM',
    layoutSva: 'NENHUM',
    layoutVod: 'NENHUM',
    channels: { items: [] },
    svas: { items: [] },
    flexibleTables: '',
    increasePercentage: 0,
    disabled: false,
    disabledDate: '',
  },
  loading: 0,
};

const BroadcasterProvider = ({ children }) => {
  const [state, dispatch] = useReducer(BroadcasterReducer, INIT_STATE);

  return (
    <BroadcasterContext.Provider value={{ state, dispatch }}>
      {children}
    </BroadcasterContext.Provider>
  );
};

export default BroadcasterProvider;
