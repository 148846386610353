import React, { useState, useEffect, useRef } from 'react';

import { Link } from 'react-router-dom';
import { Add } from '@mui/icons-material';
import {
  Box,
  Fab,
  Grid,
  Tooltip,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { red, indigo, green, grey, blue } from '@mui/material/colors';
import * as queries from 'graphql/queries';
import * as customGql from 'graphql/custom/functions';
import {
  NotificationManager,
  NotificationContainer,
} from 'react-notifications';
import Loading from 'util/loading';

const styles = {
  styleBar: {
    backgroundColor: 'white',
    borderRadius: 2,
    marginBottom: 2,
    padding: 2,
    textAlign: 'center',
  },
  listBall: {
    padding: 1,
    borderRadius: 3,
    backgroundColor: indigo[500],
    backgroundSize: 'contain',
    width: 160,
    height: 60,
    textAlign: 'center',
    marginRight: 1,
    marginBottom: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: `white`,
  },
};

const BroadcasterListPage = () => {
  const [broadcasters, setBroadcasters] = useState([]);
  const [loading, setLoading] = useState(false);
  const [includeDisabled, setIncludeDisabled] = useState(false);

  function compare(a, b) {
    if (a.trade > b.trade) return 1;
    if (b.trade > a.trade) return -1;

    return 0;
  }

  const disabledFilter = function (aBroadcaster) {
    if (includeDisabled) {
      return true;
    } else {
      return !Boolean(aBroadcaster.disabled);
    }
  };

  const handleListFilter = function (aEvent) {
    setIncludeDisabled(aEvent.target.checked);
  };

  useEffect(() => {
    updateBroadcasterList();
  }, [includeDisabled]);

  const updateBroadcasterList = async function () {
    try {
      setLoading(true);
      const result = await customGql.listAll(queries.listBroadcasters);
      setBroadcasters(result.filter(disabledFilter).sort(compare));
    } catch (error) {
      NotificationManager.error(error.toString(), null, 3000);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="app-wrapper">
      <Loading loading={loading} />
      <NotificationContainer />

      <Grid container sx={styles.styleBar}>
        <Grid item xs={11}>
          <h2>Parceiros</h2>
        </Grid>
        <Grid item xs={1}>
          <Tooltip title={'Adicionar programador'}>
            <Link to="/broadcaster/000">
              <Fab
                size="small"
                sx={{
                  color: 'white',
                  backgroundColor: indigo[500],
                  marginLeft: 'auto',
                }}
              >
                <Add />
              </Fab>
            </Link>
          </Tooltip>
        </Grid>
        <Grid item xs={8}>
          &nbsp;
        </Grid>
        <Grid item xs={4}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={includeDisabled}
                  onChange={(e) => handleListFilter(e)}
                />
              }
              label="Incluir desabilitadas"
            />
          </FormGroup>
        </Grid>
      </Grid>

      {/* 
          -------------------------
          Lista de parceiros
          ------------------------- 
      */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          gap: 1,
        }}
      >
        {broadcasters.map((item, idx) => (
          <Box key={idx} sx={styles.listBall}>
            <Link to={`/broadcaster/${item.id}`} style={{ color: 'white' }}>
              {item.trade}
            </Link>
          </Box>
        ))}
      </Box>
    </div>
  );
};

export default BroadcasterListPage;
