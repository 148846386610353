import React, { useEffect, useState, useRef, Fragment } from 'react';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { Card, CardContent, CardHeader, CardActions } from '@mui/material';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { Avatar } from '@mui/material';
import Fab from '@mui/material/Fab';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { Computer, Delete } from '@mui/icons-material';
import Button from '@mui/material/Button';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import Loading from 'util/loading';
import Collapse from '@mui/material/Collapse';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import * as queries from 'graphql/queries';
import * as customGql from 'graphql/custom/functions';
import { styled } from '@mui/material/styles';
import { default as AlertDialog } from 'components/Neo/Alert';
import * as subscriptions from 'graphql/subscriptions';
import { API, Auth, graphqlOperation } from 'aws-amplify';

// precisa ser o mesmo ID usado na hora de gravar mensagens lá no AWS Batch
const AWS_BATCH_SERVER_ID = '41d4b2a9-45a0-4c31-b51a-eba94180b2a8';

function initialDate() {
  let dt = new Date();
  dt.setDate(10);
  dt.setMonth(dt.getMonth() - 1);
  return dt;
}

const styles = {
  topBox: {
    columnGap: 12,
    // '&:hover': {
    //   backgroundColor: '#eaeaea',
    // },
  },
  topBoxButton: {
    flexGrow: 2,
    marginLeft: 6,
  },
  cards: {
    marginTop: 6,
  },
  broadcaster: {
    borderRadius: 12,
    borderStyle: 'solid',
    borderWidth: 1,
    paddingLeft: 1,
    paddingRight: 1,
    margin: 1,
  },
};

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const ParserItem = (props) => {
  const { item } = props;

  const [alert, setAlert] = useState({
    severity: 'info',
    message: '',
    open: false,
  });

  // data selecionada
  const iDate = initialDate();
  const [refDate, setRefDate] = useState(iDate);

  // assinatura (subscription) para as mensagens de geração de relatório
  // recebe mensagem quando tem novidade (arquivo sendo gerado ou que já acabou)
  const [messageSubscription, setMessageSubscription] = useState(null);

  const scrollRef = useRef(null);

  const [logMessages, setLogMessages] = useState([]);

  const [broadcasters, setBroadcasters] = useState([]);

  const [broadcastersManagerial, setBroadcastersManagerial] = useState([]);

  const [loading, setLoading] = useState(false);

  const [showDialog, setShowDialog] = useState(false);

  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const addMessage = (aText) => {
    const d = new Date();
    const msg = d.toLocaleString() + ' - ' + aText;
    setLogMessages((oldArray) => [...oldArray, msg]);
  };

  const processMessage = async (aProvider, aValue) => {
    //console.log({ aProvider, aValue });
    const message = JSON.parse(aValue.data.onCreateMessage.message);

    if (message.id === AWS_BATCH_SERVER_ID) {
      addMessage(message.contents);
    }
  };

  // faz a assinatura para receber as mensagens (de quando um relatório terminou de gerar)
  const createMessageSubscription = async () => {
    // só para garantir
    await cancelMessageSubscription();

    const s = await API.graphql(
      graphqlOperation(subscriptions.onCreateMessage)
    ).subscribe({
      next: ({ provider, value }) => {
        //console.log({ provider, value });
        processMessage(provider, value);
      },
      error: (error) => {
        console.log(error);
      },
    });

    setMessageSubscription(s);
  };

  const cancelMessageSubscription = async () => {
    if (messageSubscription) {
      await messageSubscription.unsubscribe();
      setMessageSubscription(null);
    }
  };

  // inicial - inscreve-se para receber mensagens
  useEffect(() => {
    createMessageSubscription();
    console.log('message subscription');

    return function cleanup() {
      // Stop receiving data updates from the subscription
      cancelMessageSubscription();
    };
  }, []);

  // inicial - pega a lista de programadoras
  useEffect(() => {
    updateBroadcasterList();

    return function cleanup() {};
  }, []);

  // chamado quando a variável logMessages é alterada
  // garante que mostra a última recebida
  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behaviour: 'smooth' });
    }
  }, [logMessages]);

  const updateBroadcasterList = async function () {
    try {
      setLoading(true);
      const result = await customGql.listAll(queries.listBroadcasters);
      const b = [];
      const bManagerial = [];

      for (const broadcaster of result) {
        if (!Boolean(broadcaster.disabled)) {
          // TODO
          // está horrível assim hardcoded
          // mudar para um flag no "parceiro" que indica que é um relatório gerencial
          if (
            broadcaster.id === '31e6bd39-0b05-4ff2-b931-0a710756fae5' ||
            broadcaster.id === '5ae77df9-7b01-4e0a-ad88-bd62359e54d5' ||
            broadcaster.id === '7260d436-b16d-4d6b-8c6c-7989cfb45a6b' ||
            broadcaster.id === '0f6cde82-9be6-4572-9754-28d81ceefed7' ||
            broadcaster.id === '5efe307c-fed7-4a17-be2c-cd0358c63ca0'
          ) {
            bManagerial.push({
              id: broadcaster.id,
              trade: broadcaster.trade,
              selected: true,
            });
          } else {
            b.push({
              id: broadcaster.id,
              trade: broadcaster.trade,
              selected: true,
            });
          }
        }
      }
      function compare(a, b) {
        if (a.trade > b.trade) return 1;
        if (b.trade > a.trade) return -1;

        return 0;
      }

      setBroadcasters(b.sort(compare));
      setBroadcastersManagerial(bManagerial.sort(compare));
    } catch (error) {
      setAlert({
        message: error.toString(),
        severity: 'error',
        open: true,
      });
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  // quando clica no botão de gerar relatório
  async function handleStartJob() {
    try {
      setLoading(true);
      setShowDialog(false);

      // monta o parâmetro period no formato 2022-12
      const m = (refDate.getMonth() + 1).toString().padStart(2, '0');
      const y = refDate.getFullYear().toString();
      const period = y + '-' + m;

      const user = await Auth.currentAuthenticatedUser({
        bypassCache: true,
      });

      const b = [];
      const joinBroadcasters = broadcasters.concat(broadcastersManagerial);
      for (const broadcaster of joinBroadcasters) {
        if (broadcaster.selected) {
          b.push(broadcaster.id);
        }
      }

      const apiName = 'lambdasApi';
      const path = '/report';
      const options = {
        body: {
          period: period,
          user: user.username,
          broadcasters: b,
        },
        headers: {},
        // queryStringParameters: {
        //   period: period,
        //   user: user.username,
        // },
      };

      // Chama a API que dispara o lambda submitJob
      // que por sua vez vai disparar o job no AWS Batch - Docker Container que gera
      // de fato os relatórios.
      // No momento da escrita dessa rotina, o amplify não tem método para chamar
      // diretamente o AWS Batch.
      const response = await API.post(apiName, path, options);

      addMessage('JOB ID: ' + response.jobId);
      addMessage('Ligando máquina virtual na AWS...');

      setAlert({
        message: 'Solicitação enviada',
        severity: 'success',
        open: true,
      });
    } catch (error) {
      console.log(error);

      let msg = error.toString();
      if ('response' in error) {
        msg = error.response.data.toString();
      }

      addMessage(msg);
      setAlert({
        message: msg,
        severity: 'error',
        open: true,
      });
    } finally {
      setLoading(false);
    }
  }

  // quando usuário clica no botão para mudar o mês
  function handleChangeMonth(event) {
    const m = event.target.value;
    const d = new Date(refDate);
    d.setMonth(m);
    setRefDate(d);
  }

  // quando usuário clica no botão para mudar o ano
  function handleChangeYear(event) {
    const y = event.target.value;
    const d = new Date(refDate);
    d.setFullYear(y);
    setRefDate(d);
  }

  function handleClearLog(event) {
    setLogMessages([' ']);
  }

  // quando usuário clica no botão para fechar o alerta
  function handleCloseAlert(event) {
    setAlert({
      message: '',
      severity: 'info',
      open: false,
    });
  }

  // retorna os anos a partir de 2020
  function getYearsList() {
    const years = [];
    for (let i = new Date().getFullYear(); i >= 2020; i--) {
      years.push(i);
    }
    return years;
  }

  function onShowDialog(event) {
    setShowDialog(true);
  }

  function onHideDialog(event) {
    setShowDialog(false);
  }

  function handleSelectBroadcaster(aEvent, aId) {
    const broadcastersTemp = [...broadcasters];
    for (const [index, value] of broadcastersTemp.entries()) {
      if (value.id === aId) {
        broadcastersTemp[index].selected = aEvent.target.checked;
      }
    }
    setBroadcasters(broadcastersTemp);
  }

  function handleSelectBroadcasterManagerial(aEvent, aId) {
    const broadcastersTemp = [...broadcastersManagerial];
    for (const [index, value] of broadcastersTemp.entries()) {
      if (value.id === aId) {
        broadcastersTemp[index].selected = aEvent.target.checked;
      }
    }
    setBroadcastersManagerial(broadcastersTemp);
  }

  function runOnCheckAll(aValue) {
    onCheckAll(aValue);
    onCheckAllManagerial(aValue);
  }

  function onCheckAll(aValue) {
    const broadcastersTemp = [...broadcasters];
    for (const [index, value] of broadcastersTemp.entries()) {
      broadcastersTemp[index].selected = aValue;
    }
    setBroadcasters(broadcastersTemp);
  }

  function onCheckAllManagerial(aValue) {
    const broadcastersTemp = [...broadcastersManagerial];
    for (const [index, value] of broadcastersTemp.entries()) {
      broadcastersTemp[index].selected = aValue;
    }
    setBroadcastersManagerial(broadcastersTemp);
  }

  return (
    <Fragment>
      <Loading loading={Boolean(loading)} />

      <Snackbar
        open={alert.open}
        autoHideDuration={4000}
        onClose={(e) => handleCloseAlert(e)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Alert severity={alert.severity}>{alert.message}</Alert>
      </Snackbar>

      <AlertDialog
        show={Boolean(showDialog)}
        onCancel={(e) => onHideDialog(e)}
        onOk={(e) => handleStartJob(e)}
        body="Este procedimento pode levar algum tempo. Quer continuar?"
        title="Processar planilhas do período selecionado"
      />

      <Card className="shadow border-0">
        <CardContent>
          <Box display="flex" flexDirection="row" sx={styles.topBox}>
            <FormControl className="w-100" variant="standard">
              <InputLabel>Mês</InputLabel>
              <Select
                name="month"
                value={refDate.getMonth()}
                onChange={(e) => handleChangeMonth(e)}
                variant="standard"
              >
                {[...Array(12).keys()].map((item, idx) => (
                  <MenuItem key={idx} value={idx}>
                    {(idx + 1).toString().padStart(2, '0')}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className="w-100" variant="standard">
              <InputLabel>Ano</InputLabel>
              <Select
                name="year"
                value={refDate.getFullYear()}
                onChange={(e) => handleChangeYear(e)}
                variant="standard"
              >
                {getYearsList().map((item, idx) => (
                  <MenuItem key={idx} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Button
              variant="contained"
              type="submit"
              color="primary"
              onClick={(e) => {
                onShowDialog(e);
              }}
              sx={styles.topBoxButton}
            >
              <span className="ml-2">Processar planilhas de entrada</span>
            </Button>
          </Box>
        </CardContent>
        <CardActions disableSpacing>
          Parceiros
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                gap: 1,
              }}
            >
              {broadcasters.map((item, idx) => (
                <Box key={item.id} sx={styles.broadcaster}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={item.selected}
                          onChange={(e) => handleSelectBroadcaster(e, item.id)}
                        />
                      }
                      label={item.trade}
                    />
                  </FormGroup>
                </Box>
              ))}
            </Box>
            <h1>Relatórios Gerenciais</h1>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                gap: 1,
              }}
            >
              {broadcastersManagerial.map((item, idx) => (
                <Box key={item.id} sx={styles.broadcaster}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={item.selected}
                          onChange={(e) =>
                            handleSelectBroadcasterManagerial(e, item.id)
                          }
                        />
                      }
                      label={item.trade}
                    />
                  </FormGroup>
                </Box>
              ))}
            </Box>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              onClick={(e) => {
                runOnCheckAll(true);
              }}
              sx={styles.topBoxButton}
            >
              Marcar todas
            </Button>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              onClick={(e) => {
                runOnCheckAll(false);
              }}
              sx={styles.topBoxButton}
            >
              Desmarcar todas
            </Button>
          </CardContent>
        </Collapse>
      </Card>

      <Card sx={styles.cards}>
        <CardHeader
          avatar={
            <Avatar sx={{ bgcolor: '#a4dff2' }}>
              <Computer />
            </Avatar>
          }
          title="Mensagens do servidor"
        />
        <CardContent
          sx={{
            overflow: 'scroll',
            height: 350,
          }}
        >
          <Box ref={scrollRef} />
          {Array.from({ length: 8 }, (x, i) => i - 8).map((item, idx) => (
            <Typography key={idx} variant="subtitle1" gutterBottom>
              {logMessages.at(item)}
            </Typography>
          ))}
        </CardContent>
      </Card>
    </Fragment>
  );
};

export default ParserItem;
