import * as actions from './actions';

const OperatorReducer = (prevState, action) => {
  switch (action.type) {
    case actions.EDIT_REGION:
      return {
        ...prevState,
        edit_region_modal: true,
        currentRegionEditor: action.payload,
      };

    case actions.ADD_REGIONS:
      return {
        ...prevState,
        add_region_modal: true,
      };

    case actions.ADD_GROWTHPLANS: {
      prevState.operator.growthPlans.items.push(action.payload);
      return {
        ...prevState,
      };
    }

    case actions.HIDE_EDIT_REGION:
      return {
        ...prevState,
        edit_region_modal: false,
      };

    case actions.HIDE_ADD_REGION:
      return {
        ...prevState,
        add_region_modal: false,
      };

    case actions.HIDE_EDIT_CONTRACT:
      return {
        ...prevState,
        edit_contract_modal: false,
      };

    case actions.SHOW_EDIT_CONTRACT:
      return {
        ...prevState,
        edit_contract_modal: true,
      };

    case actions.SET_CURRENT_CONTRACT:
      return {
        ...prevState,
        currentContract: action.payload,
      };

    case actions.LOADING:
      {
        let l = prevState.loading + (action.payload ? 1 : -1);
        return {
          ...prevState,
          loading: l,
        };
      }
      break;

    case actions.GRAPHQL_ERROR:
      return {
        ...prevState,
        loading: false,
      };

    case actions.ALERT_REMOVE_OPERATOR:
      return {
        ...prevState,
        alert_delete_operator: action.payload,
      };

    case actions.ALERT_REMOVE_REGION:
      return {
        ...prevState,
        alert_delete_region: action.payload,
      };

    case actions.ALERT_REMOVE_CONTRACT:
      return {
        ...prevState,
        alert_delete_contract: action.payload,
      };

    case actions.SET_OPERATOR:
      return {
        ...prevState,
        operator: action.payload,
      };

    case actions.GO_HOME:
      return {
        ...prevState,
        redirect: true,
      };

    case actions.MODAL_BROADCASTER:
      return {
        ...prevState,
        modalBroadcaster: action.payload,
      };

    case actions.MODAL_REGION:
      return {
        ...prevState,
        modalRegion: action.payload,
      };

    case actions.REMOVE_CONTRACT:
      {
        let operatorCopy = Object.assign({}, prevState.operator);
        let index = -1;
        prevState.operator.contracts.items.forEach(function (contract, idx) {
          if (contract.id === action.payload) {
            index = idx;
          }
        });
        if (index !== -1) {
          prevState.operator.contracts.items.splice(index, 1);
        }

        return {
          ...prevState,
          operator: operatorCopy,
        };
      }
      break;

    case actions.REMOVE_REGION:
      {
        let operatorCopy = Object.assign({}, prevState.operator);
        let index = -1;
        prevState.operator.regions.items.forEach(function (region, idx) {
          if (region.id === action.payload) {
            index = idx;
          }
        });
        if (index !== -1) {
          prevState.operator.regions.items.splice(index, 1);
        }

        return {
          ...prevState,
          operator: operatorCopy,
        };
      }
      break;

    case actions.REMOVE_GROWTPLANS:
      {
        let operatorCopy = Object.assign({}, prevState.operator);
        let index = -1;
        prevState.operator.growthPlans.items.forEach(function (item, idx) {
          if (item.id === action.payload) {
            index = idx;
          }
        });
        if (index !== -1) {
          prevState.operator.growthPlans.items.splice(index, 1);
        }

        return {
          ...prevState,
          operator: operatorCopy,
        };
      }
      break;

    case actions.EDIT_OPERATOR:
      {
        let f = action.payload.field;
        let v = action.payload.value;
        prevState.operator[f] = v;
        return {
          ...prevState,
        };
      }
      break;

    default: {
      return {
        ...prevState,
      };
    }
  }
};
export default OperatorReducer;
