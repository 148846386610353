import React, { useReducer } from 'react';
import OperatorContext from './context';
import OperatorReducer from './reducer';
import { v4 } from 'uuid';

const INIT_STATE = {
  operator: {
    id: v4(),
    company_name: '',
    trade: '',
    corporate_registry: '',
    headend: 'unknown',
    evolution: false,
    gatewayId: 'unknown',
    econoGroup: '',
    disabled: false,
    disabledDate: '',
    regions: {
      items: [],
    },
    growthPlans: {
      items: [],
    },
    contracts: {
      items: [],
    },
  },
  modalBroadcaster: false,
  modalRegion: false,
  loading: 0,
  add_region_modal: false,
  edit_region_modal: false,
  edit_lineup_modal: false,
  edit_contract_modal: false,
  currentContract: {
    id: '',
    broadcaster: {
      id: '',
      trade: '',
      layout: 'GERAL_SDHD',
    },
    contract_number: '',
    customer_code: '',
    distribution: 'HD_exclusive',
    packing: '',
    startDate: '',
    billingCode: '',
    lineup: {
      items: [],
    },
  },
  currentRegionEditor: {
    technology: 'IPTV',
    municipality: {
      state: '',
      name: '',
    },
  },
};

const OperatorProvider = ({ children }) => {
  const [state, dispatch] = useReducer(OperatorReducer, INIT_STATE);

  return (
    <OperatorContext.Provider value={{ state, dispatch }}>
      {children}
    </OperatorContext.Provider>
  );
};

export default OperatorProvider;
