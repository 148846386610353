import React from 'react';
import MainContainer from './container';

// amplify
import { Amplify, I18n } from 'aws-amplify';
import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui/styles.css';

// animate
import 'animate.css';

import awsconfig from 'aws-exports';
Amplify.configure(awsconfig);

/*
// Para não persistir o login quando fecha o browser
Amplify.configure({
 Auth: {
  storage: window.sessionStorage
 }
})
*/

// TODO jogar para arquivo separado
const authScreenLabels = {
  pt: {
    Username: 'Email',
    'Sign in to your account': 'Faça seu login',
    Password: 'Senha',
    'Enter your password': 'insira sua senha',
    'Forgot your password? ': 'Esqueceu sua senha? ',
    'Reset password': 'Redefinir senha',
    'Sign in': 'Entrar',
    'Signing in': 'Entrando',
    'No account? ': 'Não tem conta? ',
    'Create account': 'Criar conta',
    'Reset your password': 'Redefinir senha',
    'Enter your username': 'email',
    'Back to Sign In': 'Voltar para o login',
    'Send code': 'Enviar código',
    Code: 'Código',
    'New password': 'Nova Senha',
    'Resend code': 'Re-enviar Código',
    Submit: 'Enviar',
    'Change password': 'Mudar senha',
  },
};
I18n.setLanguage('pt');
I18n.putVocabularies(authScreenLabels);

const MainApp = () => <MainContainer />;
export default withAuthenticator(MainApp);
