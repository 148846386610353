import React, { useState, Fragment } from 'react';
import { Link } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';

import { Auth } from 'aws-amplify';
import { ExitToApp } from '@mui/icons-material';
import Tooltip from '@mui/material/Tooltip';
import Alert from 'components/Neo/Alert';
import Menu from './Menu';
import Box from '@mui/material/Box';
import { red, indigo, green, grey, blue } from '@mui/material/colors';

const HorizontalHeader = () => {
  const [logoutDialog, setLogoutDialog] = useState(false);

  const onLogout = (e) => {
    setLogoutDialog(false);
    Auth.signOut();
  };

  return (
    <AppBar
      sx={{ backgroundColor: indigo[500], color: 'white', position: 'sticky' }}
    >
      <Toolbar
        sx={{
          height: 60,
        }}
      >
        {/* LOGO */}
        <Link to="/">
          <Box
            component="img"
            src="/images/logo.png"
            sx={{
              height: 40,
              padding: 1,
            }}
          />
        </Link>

        <Box width={300}></Box>

        <Menu />

        <Tooltip title={'Sair'}>
          <IconButton
            size="small"
            sx={{
              ml: 'auto',
              color: 'white',
            }}
            onClick={() => {
              setLogoutDialog(true);
            }}
          >
            <ExitToApp />
          </IconButton>
        </Tooltip>

        <Alert
          show={logoutDialog}
          onCancel={() => {
            setLogoutDialog(false);
          }}
          onOk={onLogout}
          body={'Tem certeza?'}
          title={'Sair'}
        />
      </Toolbar>
    </AppBar>
  );
};

export default HorizontalHeader;
