import React from 'react';
import WarningItem from './item';
const WarningPage = () => {
  return (
    <div className="app-wrapper">
      <WarningItem />
    </div>
  );
};
export default WarningPage;
