import React, {
  useContext,
  useState,
  useEffect,
  useRef,
  Fragment,
} from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControlLabel,
  FormControl,
  FormHelperText,
  Switch,
  Box,
  Tooltip,
  Snackbar,
  Card,
  CardContent,
  CardHeader,
  Alert,
  Button,
  Select,
  MenuItem,
  InputLabel,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
} from '@mui/material';

import { Backup, Add, Delete, ExpandMore } from '@mui/icons-material';

import * as mutations from 'graphql/mutations';
import * as queries from 'graphql/queries';
import { API, graphqlOperation } from 'aws-amplify';
import { v4 } from 'uuid';

import * as customQueries from 'graphql/custom/queries';
import * as customGql from 'graphql/custom/functions';

import Loading from 'util/loading';

const styles = {
  vod: {
    display: 'flex',
    flexDirection: 'row',
    gap: 2,
    marginBottom: 1,
    justifyContent: 'space-between',
    '&:hover': {
      backgroundColor: '#eeeeee',
    },
  },
  partnerBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  },
  addPartnerBox: {
    display: 'flex',
    flexDirection: 'row',
    gap: 2,
    justifyContent: 'flex-start',
    marginBottom: 2,
  },
  dialogTitle: {
    borderBottom: '2px solid black',
    marginBottom: 2,
    minWidth: 400,
  },
};

function plain(str) {
  return str
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');
}

function comparePartners(a, b) {
  if (plain(a.trade) > plain(b.trade)) {
    return 1;
  }

  if (plain(a.trade) < plain(b.trade)) {
    return -1;
  }

  return 0;
}

function filterDisabled(aItem) {
  return !Boolean(aItem.disabled);
}

function filterPartnerWithVod(aPartner) {
  return aPartner.vod && aPartner.vod.items && aPartner.vod.items.length > 0;
}

const OperatorVods = ({ operatorId }) => {
  // Parceiros com VOD, disponíveis para habilitar no operador
  const [availablePartners, setAvailablePartners] = useState([]);
  const [operatorVods, setOperatorVods] = useState([]);
  const [errorAlert, setErrorAlert] = useState(null);
  const [loading, setLoading] = useState(false);
  const [expanded, setExpanded] = useState(false);

  // parceiro selecionado na caixa de diálogo de adicionar novo parceiro
  const [selectedPartnerId, setSelectedPartnerId] = useState('');

  // parceiros exibidos na listagem
  const [currentPartners, setCurrentPartners] = useState([]);
  const [partnerDialogOpened, setPartnerDialogOpened] = useState(false);

  /**
   *
   * Puxa a listagem de todos os parceiros com seus vods
   */
  const listPartners = async function () {
    try {
      setLoading(true);
      const allPartners = await customGql.listAll(
        customQueries.listBroadcasters
      );
      const p = allPartners.filter(filterPartnerWithVod).sort(comparePartners);
      setAvailablePartners(p);
    } catch (error) {
      setErrorAlert(error.toString());
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    listPartners();
  }, []);

  useEffect(() => {
    if (operatorId.length > 0) {
      updateOperatorVods();
    }
  }, [operatorId]);

  useEffect(() => {
    setCurrentPartners(availablePartners.filter(hasVodEnabled));
  }, [availablePartners, operatorVods]);

  const handleChangeAccordion = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  // indica se o operador tem algum vod habilitado com aquele parceiro
  // ou se está no array currentPartners (que é quando clica no botão, para depois acrescentar o vod)
  const hasVodEnabled = function (aPartner) {
    for (let i = 0; i < aPartner.vod.items.length; i++) {
      const vod = aPartner.vod.items[i];

      if (hasVod(vod.id)) {
        return true;
      }
    }

    for (let i = 0; i < currentPartners.length; i++) {
      if (currentPartners[i].id === aPartner.id) {
        return true;
      }
    }

    return false;
  };

  // indica se o vod está habilitado ou não no operador
  const hasVod = function (aVodId) {
    for (const vod of operatorVods) {
      if (vod.vodId === aVodId) return true;
    }
    return false;
  };

  const updateOperatorVods = async function () {
    try {
      setLoading(true);

      const result = await API.graphql(
        graphqlOperation(queries.listV2OByOperator, {
          operatorId: operatorId,
          limit: 1000,
        })
      );

      if (result.errors) {
        console.log(result.errors);
        throw new Error(result.errors[0].message);
      }

      setOperatorVods(result.data.listV2OByOperator.items);
    } catch (error) {
      setErrorAlert(error.toString());
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  /**
   * Acrescenta o VOD ao operador (na tabela Vod2Operator)
   */
  const onAddVod = async function (aVodId) {
    try {
      setLoading(true);

      const myInput = {
        id: v4(),
        vodId: aVodId,
        vod2OperatorVodId: aVodId,
        vod2OperatorOperatorId: operatorId,
        operatorId: operatorId,
      };

      const operation = mutations.createVod2Operator;

      const result = await API.graphql(
        graphqlOperation(operation, { input: myInput })
      );

      if (result.errors) {
        console.log(result.errors);
        throw new Error(result.errors[0].message);
      }

      updateOperatorVods();
    } catch (error) {
      setErrorAlert(error.toString());
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  function handleToggle(aEvent, aVodId) {
    const vodEnabled = aEvent.target.checked;

    if (vodEnabled) {
      onAddVod(aVodId);
    } else {
      onDeleteVod(aVodId);
    }
  }

  const onDeleteVod = async function (aVodId) {
    try {
      setLoading(true);

      // pega o ID do objeto Vod2Operator (tabela)
      // pelo ID do vod
      let id = null;
      for (const v2o of operatorVods) {
        if (v2o.vodId === aVodId) {
          id = v2o.id;
        }
      }

      if (id) {
        const myInput = {
          id: id,
        };

        const operation = mutations.deleteVod2Operator;

        const result = await API.graphql(
          graphqlOperation(operation, { input: myInput })
        );

        if (result.errors) {
          console.log(result.errors);
          throw new Error(result.errors[0].message);
        }

        updateOperatorVods();
      }
    } catch (error) {
      setErrorAlert(error.toString());
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const addCurrentPartner = function () {
    // primeiro verifica se já não está na lista
    for (let i = 0; i < currentPartners.length; i++) {
      if (currentPartners[i].id === selectedPartnerId) {
        setErrorAlert(`${currentPartners[i].trade} já está na lista`);
        return;
      }
    }

    // se não está, então adiciona
    const partner = availablePartners.find((p) => p.id === selectedPartnerId);

    setCurrentPartners((currentPartners) => [...currentPartners, partner]);
  };

  return (
    <Fragment>
      <Loading loading={loading} />

      <Box sx={styles.addPartnerBox}>
        <Button
          variant="contained"
          onClick={(e) => setPartnerDialogOpened(true)}
          color="primary"
        >
          <Add />
          Adicionar Parceiro VOD
        </Button>
      </Box>

      {/* Caixa com a listagem dos VODs por parceiro*/}
      <Box sx={styles.partnerBox}>
        {currentPartners.map((partner, idx) => (
          <Accordion
            expanded={expanded === partner.id}
            onChange={handleChangeAccordion(partner.id)}
            key={idx}
          >
            <AccordionSummary expandIcon={<ExpandMore />}>
              {partner.trade}
            </AccordionSummary>

            <AccordionDetails>
              {partner.vod.items.filter(filterDisabled).map((vod, idx) => (
                <FormControl key={vod.id} sx={styles.vod}>
                  <Box>{vod.name}</Box>

                  <FormControl>
                    <Tooltip title="Habilitar/Desabilitar">
                      <FormControlLabel
                        control={
                          <Switch
                            color="primary"
                            onClick={(event) => handleToggle(event, vod.id)}
                            checked={hasVod(vod.id)}
                          />
                        }
                        label="Habilitado"
                      />
                    </Tooltip>
                  </FormControl>
                </FormControl>
              ))}
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>

      {/* Mensagens de erro  */}
      <Snackbar
        open={Boolean(errorAlert)}
        autoHideDuration={1500}
        onClose={() => setErrorAlert(null)}
      >
        <Alert
          onClose={() => setErrorAlert(null)}
          severity="error"
          variant="filled"
          sx={{ width: '100%' }}
        >
          {errorAlert}
        </Alert>
      </Snackbar>

      {/* Dialog para inclusão de parceiro */}
      <Dialog open={partnerDialogOpened} sx={styles.dialog}>
        <DialogTitle sx={styles.dialogTitle}>
          Selecione o parceiro VOD
        </DialogTitle>
        <DialogContent>
          <FormControl>
            <InputLabel
              sx={{
                margin: 1,
              }}
            >
              Parceiro
            </InputLabel>
            <Select
              label="Parceiro"
              sx={{
                width: 220,
              }}
              value={selectedPartnerId}
              onChange={(e) => setSelectedPartnerId(e.target.value)}
            >
              {availablePartners.sort(comparePartners).map((partner, idx) => {
                return (
                  <MenuItem key={partner.id} value={partner.id}>
                    {partner.trade}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={(e) => setPartnerDialogOpened(false)}>
            Cancelar
          </Button>
          <Button
            disabled={selectedPartnerId.length === 0}
            onClick={(e) => {
              addCurrentPartner();
              setPartnerDialogOpened(false);
            }}
          >
            Adicionar
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default OperatorVods;
