import React, { useState, Fragment } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Fab from '@mui/material/Fab';
import Tooltip from '@mui/material/Tooltip';
import { Download, Delete } from '@mui/icons-material';
import Loading from 'util/loading';
import { Storage } from 'aws-amplify';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { red, indigo, green, grey, blue } from '@mui/material/colors';

const styles = {
  rowBox: {
    height: 70,
    padding: 1,
    columnGap: 12,
    '&:hover': {
      backgroundColor: '#eaeaea',
    },
  },
  sizeBox: {
    minWidth: 100,
    textAlign: 'right',
    marginRight: 12,
  },
  name: {
    flexGrow: 2,
  },
};

const ReportFile = (props) => {
  const { file } = props;
  const [loading, setLoading] = useState(false);

  const [alert, setAlert] = useState({
    severity: 'info',
    message: '',
    open: false,
  });

  // quando clica no botão de download
  async function handleDownload(event) {
    try {
      setLoading(true);
      const result = await Storage.get(file.key, {
        level: 'public',
        expires: 60,
        download: true,
        cacheControl: 'no-cache',
      });

      const url = URL.createObjectURL(result.Body);
      let dwnld = document.createElement('a');
      dwnld.setAttribute('href', url);
      dwnld.setAttribute('download', file.key.split('/').at(-1));
      document.body.appendChild(dwnld);

      let evt = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true,
        clientX: 20,
        /* whatever properties you want to give it */
      });
      dwnld.dispatchEvent(evt);
      document.body.removeChild(dwnld);
    } catch (error) {
      console.log(error);
      setAlert({
        message: error.toString(),
        severity: 'error',
        open: true,
      });
    } finally {
      setLoading(false);
    }
  }

  async function handleDelete(event) {
    try {
      setLoading(true);
      const result = await Storage.remove(file.key, { level: 'public' });
    } catch (error) {
      console.log(error);
      setAlert({
        message: error.toString(),
        severity: 'error',
        open: true,
      });
    } finally {
      setLoading(false);
      props.onChange();
    }
  }

  function handleCloseAlert() {
    setAlert({
      message: '',
      severity: 'info',
      open: false,
    });
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  return (
    <Fragment>
      <Loading loading={Boolean(loading)} />
      <Snackbar
        open={alert.open}
        autoHideDuration={4000}
        onClose={handleCloseAlert}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Alert severity={alert.severity}>{alert.message}</Alert>
      </Snackbar>

      <Box display="flex" flexDirection="row" sx={styles.rowBox}>
        <Typography variant="subtitle1" sx={styles.name} gutterBottom>
          {file.key.split('/').at(-1)}
        </Typography>

        <Typography variant="caption" gutterBottom>
          <p>
            {file.lastModified.toLocaleString('pt-BR', {
              timeZone: 'America/Sao_Paulo',
            })}
          </p>
        </Typography>

        <Typography variant="caption" gutterBottom sx={styles.sizeBox}>
          <p>{formatBytes(file.size)}</p>
        </Typography>

        <Tooltip title="Download">
          <Fab
            onClick={(e) => handleDownload(e)}
            size="small"
            sx={{
              backgroundColor: green[500],
              color: 'white',
            }}
          >
            <Download />
          </Fab>
        </Tooltip>

        <Tooltip title="Apagar">
          <Fab
            onClick={(e) => handleDelete(e)}
            size="small"
            sx={{
              color: 'white',
              backgroundColor: red[500],
              marginLeft: 'auto',
            }}
          >
            <Delete />
          </Fab>
        </Tooltip>
      </Box>
    </Fragment>
  );
};

export default ReportFile;
