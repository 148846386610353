import React, { useContext, useState, Fragment, useEffect } from 'react';
import Alert from 'components/Neo/Alert';
import { Delete, Save, Edit, Cancel } from '@mui/icons-material';
import { API, graphqlOperation } from 'aws-amplify';
import OperatorContext from './context';
import * as actions from './actions';
import * as customGql from 'graphql/custom/functions';
import { v4 } from 'uuid';
import * as mutations from 'graphql/mutations';
import * as customQueries from 'graphql/custom/queries';
import translate from 'util/translate';

import { InputLabel, MenuItem, Select } from '@mui/material';

import {
  FormControlLabel,
  FormControl,
  FormLabel,
  Switch,
  Card,
  CardHeader,
  CardActions,
  CardContent,
  Box,
  TextField,
  InputAdornment,
  Tooltip,
  IconButton,
} from '@mui/material';

import LineupItem from './lineup';
import { NotificationManager } from 'react-notifications';

const styles = {
  fieldBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    columnGap: 1,
    borderWidth: '1px',
    borderStyle: 'solid',
    borderRadius: '4px',
    borderColor: '#aaaaaa',
    padding: '6px',
  },
  lineupBox: {
    display: 'flex',
    flexDirection: 'column',
    columnGap: 1,
    marginTop: 1,
  },
  contractCard: {
    marginBottom: 8,
  },
  cardTitle: {
    fontSize: 'medium',
  },
  menuItem: {
    fontSize: 'small',
  },
  menuLabel: {
    fontSize: 'small',
  },
};

const ContractItem = (props) => {
  const { contract } = props;

  const { state, dispatch } = useContext(OperatorContext);

  const [showAlertDeleteContract, setShowAlertDeleteContract] = useState(false);

  const [currentContract, setCurrentContract] = useState(contract);
  const [channels, setChannels] = useState([]);

  const [editing, setEditing] = useState(false);

  const [distributions, setDistributions] = useState([]);
  // lista os ENUMs das distribuições definidas no .schema do banco de dados
  const listDistributions = async function () {
    try {
      dispatch({
        type: actions.LOADING,
        payload: true,
      });

      const result = await API.graphql(
        graphqlOperation(customQueries.listEnum, {
          enumType: 'Distribution',
        })
      );
      const t = [];
      for (const e of result.data.__type.enumValues) {
        t.push(e.name);
      }
      setDistributions(t);
    } catch (error) {
      NotificationManager.error(error.toString(), null, 2000);
      console.log(error);
    } finally {
      dispatch({
        type: actions.LOADING,
        payload: false,
      });
    }
  };

  useEffect(() => {
    listDistributions();

    return function cleanup() {};
  }, [setDistributions]);

  const getContract = async function () {
    try {
      dispatch({
        type: actions.LOADING,
        payload: true,
      });

      const ctr = await customGql.getContract(contract.id);
      setCurrentContract(ctr);

      const b = await customGql.getBroadcaster(contract.broadcaster.id);
      setChannels(b.channels.items);
    } catch (error) {
      console.log(error);
      const msg = error.message || error.toString();
      NotificationManager.error(msg, null, 3000);
    } finally {
      dispatch({
        type: actions.LOADING,
        payload: false,
      });
    }
  };

  function plain(str) {
    return str
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');
  }

  function compareChannels(a, b) {
    if (plain(a.name) > plain(b.name)) {
      return 1;
    }

    if (plain(a.name) < plain(b.name)) {
      return -1;
    }

    return 0;
  }

  useEffect(() => {
    getContract();
  }, [contract]);

  const onDeleteContract = async function () {
    try {
      dispatch({
        type: actions.LOADING,
        payload: true,
      });

      const result = await API.graphql(
        graphqlOperation(mutations.deleteContract, {
          input: {
            id: contract.id,
          },
        })
      );

      NotificationManager.success('Dados removidos com sucesso', null, 3000);

      // remove do array em memória
      dispatch({
        type: actions.REMOVE_CONTRACT,
        payload: contract.id,
      });
    } catch (error) {
      console.log(error);
      NotificationManager.error(error.toString(), null, 3000);
    } finally {
      dispatch({
        type: actions.LOADING,
        payload: false,
      });
      setShowAlertDeleteContract(false);
    }
  };

  const onSaveContract = async function () {
    try {
      dispatch({ type: actions.LOADING, payload: true });

      const contractInput = {
        id: currentContract.id,
        broadcasterId: currentContract.broadcaster.id,
        contractBroadcasterId: currentContract.broadcaster.id,
        operatorId: state.operator.id,
        contractOperatorId: state.operator.id,
        // campos que variam conforme a programadora
        contract_number: currentContract.contract_number,
        customer_code: currentContract.customer_code,
        distribution: currentContract.distribution,
        packing: currentContract.packing,
        startDate: currentContract.startDate,
        billingCode: currentContract.billingCode,
      };
      console.log(contractInput);

      let operation = mutations.createContract;
      if ('createdAt' in currentContract) {
        operation = mutations.updateContract;
      }

      const result = await API.graphql(
        graphqlOperation(operation, { input: contractInput })
      );

      if (result.errors) {
        console.log(result.errors);
        throw new Error(result.errors[0].message);
      }

      NotificationManager.success('Dados salvos com sucesso', null, 3000);
    } catch (error) {
      console.log(error);
      NotificationManager.error(error.toString(), null, 3000);
    } finally {
      setEditing(false);

      dispatch({ type: actions.LOADING, payload: false });
    }
  };

  const updateContractNumber = async function (aEvent) {
    let c = Object.assign({}, currentContract);
    c.contract_number = aEvent.target.value;
    setCurrentContract(c);
  };

  const updateCustomerCode = async function (aEvent) {
    let c = Object.assign({}, currentContract);
    c.customer_code = aEvent.target.value;
    setCurrentContract(c);
  };

  const updatePacking = async function (aEvent) {
    let c = Object.assign({}, currentContract);
    c.packing = aEvent.target.value;
    setCurrentContract(c);
  };

  const updateStartDate = async function (aEvent) {
    let c = Object.assign({}, currentContract);
    c.startDate = aEvent.target.value;
    setCurrentContract(c);
  };

  const updateBillingCode = async function (aEvent) {
    let c = Object.assign({}, currentContract);
    c.billingCode = aEvent.target.value;
    setCurrentContract(c);
  };

  const getLineup = function (aChannel) {
    let lineup = null;
    for (const l of currentContract.lineup.items) {
      if (l.channel.id === aChannel.id) {
        lineup = l;
      }
    }

    if (lineup === null) {
      lineup = {
        id: v4(),
        disabled: true,
        penetration: 0,
        channel: aChannel,
        contract: currentContract,
      };

      let contractCopy = Object.assign({}, currentContract);
      contractCopy.lineup.items = currentContract.lineup.items.concat([lineup]);
      setCurrentContract(contractCopy);
    }
    return lineup;
  };

  const handleChangeDistribution = function (aEvent) {
    console.log(aEvent.target.value);
    let c = Object.assign({}, currentContract);
    c.distribution = aEvent.target.value;
    setCurrentContract(c);
  };

  return (
    <Fragment>
      <Alert
        show={showAlertDeleteContract}
        onCancel={() => setShowAlertDeleteContract(false)}
        onOk={onDeleteContract}
        body={'Tem certeza? Isso irá apagar o contrato e o lineup'}
        title={'Apagar contrato'}
      />

      <Card variant="outlined" sx={styles.contractCard}>
        <CardHeader
          title={currentContract.broadcaster.trade}
          sx={{
            title: styles.cardTitle,
          }}
        />
        <CardContent>
          <Box sx={styles.fieldBox}>
            <FormControl>
              <TextField
                size="small"
                label="Número do Contrato"
                value={currentContract.contract_number}
                variant="standard"
                onChange={(event) => updateContractNumber(event)}
                disabled={!editing}
                inputProps={{ style: { fontSize: 'small' } }}
                InputLabelProps={{ style: { fontSize: 'small' } }}
              />
            </FormControl>

            <FormControl>
              <TextField
                size="small"
                label="Código do Cliente"
                value={currentContract.customer_code}
                variant="standard"
                onChange={(event) => updateCustomerCode(event)}
                disabled={!editing}
                inputProps={{ style: { fontSize: 'small' } }}
                InputLabelProps={{ style: { fontSize: 'small' } }}
              />
            </FormControl>

            <FormControl>
              <TextField
                size="small"
                label="Empacotamento"
                value={currentContract.packing}
                variant="standard"
                onChange={(event) => updatePacking(event)}
                disabled={!editing}
                inputProps={{ style: { fontSize: 'small' } }}
                InputLabelProps={{ style: { fontSize: 'small' } }}
              />
            </FormControl>

            <FormControl>
              <TextField
                size="small"
                label="Código de Cobrança"
                value={currentContract.billingCode}
                variant="standard"
                onChange={(event) => updateBillingCode(event)}
                disabled={!editing}
                inputProps={{ style: { fontSize: 'small' } }}
                InputLabelProps={{ style: { fontSize: 'small' } }}
              />
            </FormControl>

            <FormControl>
              <TextField
                size="small"
                label="Data de Início"
                value={currentContract.startDate}
                variant="standard"
                onChange={(event) => updateStartDate(event)}
                disabled={!editing}
                inputProps={{ style: { fontSize: 'small' } }}
                InputLabelProps={{ style: { fontSize: 'small' } }}
              />
            </FormControl>

            <FormControl variant="standard">
              <InputLabel sx={styles.menuLabel}>Distribuição</InputLabel>
              <Select
                name="distribution"
                value={currentContract.distribution}
                onChange={(e) => handleChangeDistribution(e)}
                variant="standard"
                disabled={!editing}
                sx={styles.menuItem}
              >
                <MenuItem value={''} disabled selected>
                  selecione distribuição
                </MenuItem>
                {distributions.map((item, idx) => (
                  <MenuItem key={idx} value={item} sx={styles.menuItem}>
                    {translate(item)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {!editing && (
              <Tooltip title="Editar campos">
                <span>
                  <IconButton
                    className="jr-btn jr-btn-xs text-gray"
                    onClick={() => setEditing(true)}
                    size="small"
                    disabled={
                      Boolean(state.operator.disabled) ||
                      Boolean(contract.broadcaster.disabled)
                    }
                  >
                    <Edit />
                  </IconButton>
                </span>
              </Tooltip>
            )}

            {!editing && (
              <Tooltip title="Remover contrato">
                <span>
                  <IconButton
                    className="jr-btn jr-btn-xs text-red"
                    onClick={() => setShowAlertDeleteContract(true)}
                    size="small"
                    disabled={Boolean(state.operator.disabled)}
                  >
                    <Delete />
                  </IconButton>
                </span>
              </Tooltip>
            )}

            {editing && (
              <Tooltip title="Salvar">
                <span>
                  <IconButton
                    className="jr-btn jr-btn-xs text-green"
                    onClick={() => onSaveContract()}
                    size="small"
                    disabled={
                      Boolean(state.operator.disabled) ||
                      Boolean(contract.broadcaster.disabled)
                    }
                  >
                    <Save />
                  </IconButton>
                </span>
              </Tooltip>
            )}
            {editing && (
              <Tooltip title="Cancelar">
                <span>
                  <IconButton
                    className="jr-btn jr-btn-xs text-gray"
                    onClick={() => {
                      setCurrentContract(contract);
                      setEditing(false);
                    }}
                    size="small"
                  >
                    <Cancel />
                  </IconButton>
                </span>
              </Tooltip>
            )}
          </Box>

          <Box sx={styles.lineupBox}>
            <div>Canais</div>
            {channels.sort(compareChannels).map((channel, idx) => (
              <LineupItem
                channel={channel}
                lineup={getLineup(channel)}
                key={channel.id}
              />
            ))}
          </Box>
        </CardContent>
      </Card>
    </Fragment>
  );
};

export default ContractItem;
