import React from 'react';
import BroadcasterProvider from './provider';
import BroadcasterItem from './broadcaster';
import { useParams } from 'react-router-dom';

const BroadcasterPage = () => {
  const { id } = useParams();

  return (
    <BroadcasterProvider>
      <div className="app-wrapper">
        <BroadcasterItem myBroadcasterId={id} />
      </div>
    </BroadcasterProvider>
  );
};
export default BroadcasterPage;
