import React from 'react';
import { useParams } from 'react-router-dom';
import OperatorProvider from './provider';
import OperatorItem from './operator';

const OperatorPage = () => {
  const { id } = useParams();

  return (
    <OperatorProvider>
      <div className="app-wrapper">
        <OperatorItem myOperatorId={id} />
      </div>
    </OperatorProvider>
  );
};
export default OperatorPage;
