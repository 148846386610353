import React, { useContext, useState, Fragment, useEffect } from 'react';

import { Backup, Delete, Edit, Save, Cancel } from '@mui/icons-material';
import {
  Tooltip,
  Button,
  TextField,
  Box,
  FormControl,
  IconButton,
  FormControlLabel,
  Switch,
  Alert,
  Snackbar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
} from '@mui/material';

import { red, indigo, green, grey, blue } from '@mui/material/colors';

import * as mutations from 'graphql/mutations';
import { API, graphqlOperation } from 'aws-amplify';

import BroadcasterContext from './context';
import * as actions from './actions';

const VodItem = (props) => {
  // variables

  const { dispatch } = useContext(BroadcasterContext);

  const [vod, setVod] = useState(props.vod);

  // indica que está em modo edição
  const [editing, setEditing] = useState(false);

  // indica que salvou com sucesso
  const [saveAlert, setSaveAlert] = useState(false);

  // indica erro
  const [errorAlert, setErrorAlert] = useState(null);

  // aviso que vai remover o item
  const [deleteAlert, setDeleteAlert] = useState(false);

  // functions

  async function deleteItem() {
    try {
      dispatch({
        type: actions.LOADING,
        payload: true,
      });

      const result = await API.graphql(
        graphqlOperation(mutations.deleteVOD, {
          input: {
            id: vod.id,
          },
        })
      );

      dispatch({ type: actions.REMOVE_VOD, payload: vod });
    } catch (error) {
      console.log(error);
      setErrorAlert(error.toString());
    } finally {
      dispatch({
        type: actions.LOADING,
        payload: false,
      });
      setDeleteAlert(false);
    }
  }

  const saveItem = async () => {
    try {
      if (vod.name.trim().length === 0) {
        throw new Error('Nome do VOD não pode ser vazio');
      }

      dispatch({
        type: actions.LOADING,
        payload: true,
      });

      const myInput = {
        broadcasterId: vod.broadcasterId,
        id: vod.id,
        name: vod.name.trim(),
        disabled: Boolean(vod.disabled),
        disabledDate: vod.disabledDate || '',
      };

      let operation = mutations.updateVOD;
      if (props.vod.name.length === 0) {
        operation = mutations.createVOD;
      }

      const result = await API.graphql(
        graphqlOperation(operation, { input: myInput })
      );

      // indica que salvou
      setSaveAlert(true);

      setEditing(false);
      dispatch({ type: actions.UPDATE_VOD, payload: vod });
    } catch (error) {
      console.log(error);
      let msg = error.toString();
      if ('errors' in error) {
        msg = error.errors[0].message;
      }

      // mostra o erro
      setErrorAlert(msg);
    } finally {
      dispatch({
        type: actions.LOADING,
        payload: false,
      });
    }
  };

  const handleCancel = function () {
    setEditing(false);
    setVod(props.vod);
  };

  const handleChangeName = function (aName) {
    let myVod = Object.assign({}, vod);
    myVod.name = aName;
    setVod(myVod);
  };

  function handleToggleDisabled(aEvent) {
    const dt = new Date();
    let myVod = Object.assign({}, vod);
    myVod.disabled = !Boolean(aEvent.target.checked);
    myVod.disabledDate = dt.toISOString();
    setVod(myVod);
  }

  // UI

  return (
    <Fragment>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          padding: 2,
          gap: '10px',
        }}
      >
        <FormControl
          sx={{
            flexGrow: 4,
          }}
        >
          <TextField
            name="vod_name"
            label="Nome"
            value={vod.name}
            onChange={(event) => handleChangeName(event.target.value)}
            required
            variant="standard"
            disabled={!Boolean(editing)}
          />
        </FormControl>

        <FormControl>
          <Tooltip title="Habilitar/Desabilitar">
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  onClick={(event) => {
                    handleToggleDisabled(event);
                  }}
                  checked={!Boolean(vod.disabled)}
                  disabled={!editing}
                />
              }
              label="Habilitado"
            />
          </Tooltip>
        </FormControl>

        <Tooltip title="Editar">
          <Box>
            <IconButton
              size="small"
              onClick={() => {
                setEditing(true);
              }}
              edge="end"
              disabled={Boolean(editing)}
              sx={{
                color: 'white',
                backgroundColor: blue[500],
                marginLeft: 'auto',
                height: 'fit-content',
              }}
            >
              <Edit />
            </IconButton>
          </Box>
        </Tooltip>

        <Tooltip title="Cancelar edição">
          <Box>
            <IconButton
              size="small"
              onClick={() => handleCancel()}
              edge="end"
              disabled={!Boolean(editing)}
              sx={{
                color: 'white',
                backgroundColor: blue[500],
                marginLeft: 'auto',
                height: 'fit-content',
              }}
            >
              <Cancel />
            </IconButton>
          </Box>
        </Tooltip>

        <Tooltip title="Salvar">
          <Box>
            <IconButton
              size="small"
              onClick={() => {
                saveItem();
              }}
              edge="end"
              disabled={!Boolean(editing)}
              sx={{
                color: 'white',
                backgroundColor: green[500],
                marginLeft: 'auto',
                height: 'fit-content',
              }}
            >
              <Save />
            </IconButton>
          </Box>
        </Tooltip>

        <Tooltip title="Remover">
          <Box>
            <IconButton
              size="small"
              className="jr-btn jr-btn-xs text-red"
              onClick={() => setDeleteAlert(true)}
              disabled={Boolean(editing)}
              sx={{
                color: 'white',
                backgroundColor: red[500],
                marginLeft: 'auto',
                height: 'fit-content',
              }}
            >
              <Delete />
            </IconButton>
          </Box>
        </Tooltip>
      </Box>
      <Snackbar
        open={Boolean(errorAlert)}
        autoHideDuration={1500}
        onClose={() => setErrorAlert(null)}
      >
        <Alert
          onClose={() => setErrorAlert(null)}
          severity="error"
          variant="filled"
          sx={{ width: '100%' }}
        >
          {errorAlert}
        </Alert>
      </Snackbar>
      <Snackbar
        open={saveAlert}
        autoHideDuration={1500}
        onClose={() => setSaveAlert(false)}
      >
        <Alert
          onClose={() => setSaveAlert(false)}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
          VOD salvo!
        </Alert>
      </Snackbar>
      <Dialog open={deleteAlert} onClose={() => setDeleteAlert(false)}>
        <DialogTitle id="alert-dialog-title">{'Remover VOD'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Quer realmente remover o VOD?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteAlert(false)} autoFocus>
            Cancelar
          </Button>
          <Button onClick={deleteItem}>Sim</Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default VodItem;
