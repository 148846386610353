import React, { useContext, useState, Fragment, useEffect } from 'react';
import Alert from 'components/Neo/Alert';
import { Delete, Save, Edit, Cancel } from '@mui/icons-material';
import BroadcasterContext from './context';
import * as actions from './actions';
import {
  Tooltip,
  InputAdornment,
  TextField,
  Box,
  FormControl,
  FormControlLabel,
  Switch,
  IconButton,
} from '@mui/material';
import {
  NotificationContainer,
  NotificationManager,
} from 'react-notifications';
import * as mutations from 'graphql/mutations';
import { API, graphqlOperation } from 'aws-amplify';

const styles = {
  nameField: {
    flexGrow: 2,
  },
  channelBox: {
    display: 'flex',
    flexDirection: 'row',
  },
};

const ChannelItem = (props) => {
  const { state, dispatch } = useContext(BroadcasterContext);
  const [showDeleteChannelAlert, setShowDeleteChannelAlert] = useState(false);
  const [showDisableChannelAlert, setShowDisableChannelAlert] = useState(false);
  const [editing, setEditing] = useState(false);
  const [newItem, setNewItem] = useState(false);

  const [reference, setReference] = useState(props.channel); // espelho do que está no DB
  const [channel, setChannel] = useState(props.channel);

  async function onDeleteChannel() {
    try {
      dispatch({
        type: actions.LOADING,
        payload: true,
      });

      const result = await API.graphql(
        graphqlOperation(mutations.deleteChannel, {
          input: {
            id: channel.id,
          },
        })
      );

      dispatch({ type: actions.REMOVE_CHANNEL, payload: channel });
    } catch (error) {
      console.log(error);
      NotificationManager.error(error.toString(), null, 3000);
    } finally {
      dispatch({
        type: actions.LOADING,
        payload: false,
      });
      setShowDeleteChannelAlert(false);
    }
  }

  useEffect(() => {
    if (!('createdAt' in channel)) {
      setEditing(true);
      setNewItem(true);
    }
  }, [props.channel]);

  const handleTogglePaytv = function (aEvent) {
    let c = Object.assign({}, channel);
    c.paytv = aEvent.target.checked;
    setChannel(c);
  };

  async function saveChannel() {
    try {
      dispatch({
        type: actions.LOADING,
        payload: true,
      });

      // TODO verificar se não tem outro com o mesmo nome antes
      // e aí salvar o canal (ou criar, se não existir)

      const channelInput = {
        broadcasterId: channel.broadcaster.id,
        channelBroadcasterId: channel.broadcaster.id,
        id: channel.id,
        name: channel.name,
        disabled: Boolean(channel.disabled),
        paytv: Boolean(channel.paytv),
      };
      console.log(channelInput);

      let operation = mutations.createChannel;

      if (!newItem) {
        operation = mutations.updateChannel;
      }

      const result = await API.graphql(
        graphqlOperation(operation, { input: channelInput })
      );

      setNewItem(false);
      setReference(channel);
      setEditing(false);
    } catch (error) {
      console.log(error);
      let msg = error.toString();
      if ('errors' in error) {
        msg = error.errors[0].message;
      }
      NotificationManager.error(msg, null, 3000);
    } finally {
      dispatch({
        type: actions.LOADING,
        payload: false,
      });
    }
  }

  const onChangeName = function (aName) {
    let c = Object.assign({}, channel);
    c.name = aName;
    setChannel(c);
  };

  const onHandleToggleDisable = function (aEvent) {
    let c = Object.assign({}, channel);
    c.disabled = !aEvent.target.checked;
    setChannel(c);
  };

  const onSaveClick = async function (aEvent) {
    if (channel.name.length === 0) {
      NotificationManager.error('Nome inválido', null, 3000);
      return;
    }
    // se está desabilitando um canal que estava habilitado, avisa
    // que vai desativar todos lineups
    if (!Boolean(reference.disabled) && Boolean(channel.disabled)) {
      setShowDisableChannelAlert(true);
      return;
    }

    saveChannel();
  };

  return (
    <Fragment>
      <Alert
        show={Boolean(showDeleteChannelAlert)}
        onCancel={() => setShowDeleteChannelAlert(false)}
        onOk={() => onDeleteChannel()}
        body={'Isso irá apagar o canal de todos os contratos.'}
        title={'Apagar canal'}
      />
      <Alert
        show={Boolean(showDisableChannelAlert)}
        onCancel={() => {
          setShowDisableChannelAlert(false);
          setChannel(reference);
        }}
        onOk={() => {
          setShowDisableChannelAlert(false);
          saveChannel();
        }}
        body={'Isso irá desabilitar o canal em todos os contratos.'}
        title={'Desabilitar canal'}
      />
      <Box sx={styles.channelBox}>
        <FormControl sx={styles.nameField}>
          <TextField
            value={channel.name}
            variant="standard"
            onChange={(event) => onChangeName(event.target.value)}
            disabled={!editing}
            required
          />
        </FormControl>

        <FormControl>
          <Tooltip title="Habilitar/Desabilitar canal">
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  onClick={(event) => {
                    onHandleToggleDisable(event);
                  }}
                  checked={!Boolean(channel.disabled)}
                  disabled={!editing}
                />
              }
              label="Habilitado"
            />
          </Tooltip>
        </FormControl>

        <FormControl className="col-md-6 col-12" variant="standard">
          <FormControlLabel
            control={
              <Switch
                color="primary"
                onClick={(event) => handleTogglePaytv(event)}
                checked={Boolean(channel.paytv)}
                disabled={!editing}
              />
            }
            label="Canal pago"
          />
        </FormControl>

        {!editing && !newItem && (
          <Tooltip title="Remover canal">
            <span>
              <IconButton
                className="jr-btn jr-btn-xs text-red"
                onClick={() => setShowDeleteChannelAlert(true)}
              >
                <Delete />
              </IconButton>
            </span>
          </Tooltip>
        )}

        {!editing && (
          <Tooltip title="Editar">
            <span>
              <IconButton
                onClick={() => {
                  setEditing(true);
                }}
                edge="end"
              >
                <Edit />
              </IconButton>
            </span>
          </Tooltip>
        )}

        {editing && (
          <Tooltip title="Salvar">
            <IconButton onClick={(event) => onSaveClick(event)} edge="end">
              <Save />
            </IconButton>
          </Tooltip>
        )}

        {editing && (
          <Tooltip title="Cancelar">
            <IconButton
              onClick={() => {
                setChannel(reference);
                setEditing(false);
              }}
              edge="end"
            >
              <Cancel />
            </IconButton>
          </Tooltip>
        )}
      </Box>

      <NotificationContainer />
    </Fragment>
  );
};

export default ChannelItem;
