import React, { Fragment } from 'react';
import { Delete } from '@mui/icons-material';
import { Tooltip, TextField, IconButton, Box } from '@mui/material';

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 1,
  },
  discount: {
    width: 212,
  },
};

const IncTableItem = (props) => {
  const { userbase, value, index, onDelete, discount } = props;

  return (
    <Fragment>
      <Box sx={styles.container}>
        <TextField
          label="Usuários"
          type="number"
          value={userbase}
          variant="standard"
          //onChange={(event) => onIncTable(event.target.value, index)}
          required
          InputProps={{
            readOnly: true,
          }}
        />
        <TextField
          label="Preço"
          type="number"
          value={value}
          variant="standard"
          //onChange={(event) => onValue(event.target.value, index)}
          required
          InputProps={{
            readOnly: true,
          }}
        />
        <Box sx={styles.discount}>
          {discount ? (
            <TextField
              label="Preço com desconto"
              type="number"
              value={discount}
              variant="standard"
              //onChange={(event) => onDiscount(event.target.value, index)}
              InputProps={{
                readOnly: true,
              }}
            />
          ) : null}
        </Box>

        <Tooltip title="Remover tabela">
          <span>
            <IconButton
              className="jr-btn jr-btn-xs text-red"
              onClick={() => onDelete(index)}
            >
              <Delete />
            </IconButton>
          </span>
        </Tooltip>
      </Box>
    </Fragment>
  );
};

export default IncTableItem;
