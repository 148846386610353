import React, { useEffect } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Box } from '@mui/material';

import HorizontalHeader from 'components/Header/horizontal';
import Footer from 'components/Footer';

import { BrowserRouter } from 'react-router-dom';

import MainRoutes from './routes';

import { theme } from './theme';

const MainContainer = () => {
  useEffect(() => {
    window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;

    // if (isIOS && isMobile) {
    //   document.body.classList.add('ios-mobile-view-height');
    // } else if (document.body.classList.contains('ios-mobile-view-height')) {
    //   document.body.classList.remove('ios-mobile-view-height');
    // }

    document.body.classList.remove('rtl');
  }, []);

  const applyTheme = createTheme(theme);

  return (
    <BrowserRouter>
      <ThemeProvider theme={applyTheme}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            height: '100vh',
          }}
        >
          <HorizontalHeader />
          <Box sx={{ padding: 2 }}>
            <MainRoutes />
          </Box>
          <Box
            sx={{
              flex: 1,
            }}
          ></Box>
          <Footer />
        </Box>
      </ThemeProvider>
    </BrowserRouter>
  );
};
export default MainContainer;
