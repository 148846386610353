import * as queries from 'graphql/queries';
import * as mutations from 'graphql/mutations';
import * as subscriptions from 'graphql/subscriptions';
import { API, graphqlOperation } from 'aws-amplify';
import { v4 } from 'uuid';

import * as actions from './actions';

const Reducer = (prevState, action) => {
  switch (action.type) {
    case actions.LOADING:
      let l = prevState.loading + (action.payload ? 1 : -1);
      return {
        ...prevState,
        loading: l,
      };

    case actions.SET_ALERT:
      return {
        ...prevState,
        alert: action.payload,
      };

    case actions.SET_OPERATORS:
      return {
        ...prevState,
        operators: action.payload,
      };

    case actions.SET_CURRENT_OPERATOR:
      return {
        ...prevState,
        currentOperator: action.payload,
      };

    case actions.SET_UPLOAD_PROGRESS:
      return {
        ...prevState,
        uploadProgress: action.payload,
      };

    case actions.SET_SHEET_WARNINGS:
      return {
        ...prevState,
        sheetWarnings: action.payload,
      };

    case actions.SET_BOTTOMNAVVALUE:
      return {
        ...prevState,
        bottomNavValue: action.payload,
      };

    case actions.SET_SHEETS:
      return {
        ...prevState,
        sheets: action.payload,
      };

    case actions.SET_REFDATE:
      return {
        ...prevState,
        refDate: action.payload,
      };

    case actions.SET_CURRENT_REGION:
      return {
        ...prevState,
        currentRegion: action.payload,
      };

    case actions.SET_CURRENT_WARNING:
      return {
        ...prevState,
        currentWarning: action.payload,
      };

    case actions.SET_SUBSCRIPTION:
      return {
        ...prevState,
        subscription: action.payload,
      };

    case actions.RECEIVE_MESSAGE:
      let m = action.payload;
      let found = false;
      let i;
      for (i = 0; i < prevState.receivedMessages.length; i++) {
        if (prevState.receivedMessages[i].id === m.id) {
          found = true;
        }
      }

      let result;
      if (!found) {
        result = {
          ...prevState,
          receivedMessages: [...prevState.receivedMessages, m],
        };
      } else {
        result = {
          ...prevState,
        };
      }
      return result;

    case actions.SET_BATCH_LOG:
      return {
        ...prevState,
        batchLog: action.payload,
      };

    case actions.SET_SHOW_LOG_BATCH_UPLOAD:
      return {
        ...prevState,
        showLogBatchUpload: action.payload,
      };

    case actions.SET_DISPONIBLE_REGIONS:
      return {
        ...prevState,
        disponibleRegions: action.payload,
      };

    case actions.SET_SELECTED_FILES:
      return {
        ...prevState,
        selectedFiles: action.payload,
      };

    default:
      return {
        ...prevState,
      };
  }
};
export default Reducer;
