export const ADD_REGIONS = 'add_region';
export const UPDATE_REGION = 'update_region';
export const UPDATE_GROWTHPLANS = 'update_growthplans';
export const ADD_GROWTHPLANS = 'add_growthplans';
export const ALERT_REMOVE_REGION = 'alert_dialog_region';
export const HIDE_ALERT_REMOVE_REGION = 'hide_alert_dialog_region';
export const EDIT_REGION = 'edit_region';
export const HIDE_EDIT_REGION = 'editregion_dialog_hide';
export const HIDE_ADD_REGION = 'ADDregion_dialog_hide';
export const LOADING = 'loading_req';
export const LIST_CHANNELS_SUCCESS = 'list_channel_success';
export const GRAPHQL_ERROR = 'graphql_error';
export const ALERT_REMOVE_OPERATOR = 'alert_dialog_operator';
export const SET_OPERATOR = 'set_operator';
export const GO_HOME = 'go_home';
export const EDIT_OPERATOR = 'edit_operator';
export const SET_NEW_OPERATOR = 'set_new_operator';
export const SHOW_EDIT_CONTRACT = 'how_sedit_contract';
export const SET_CURRENT_CONTRACT = 'set_current_contract';
export const HIDE_EDIT_CONTRACT = 'editcontract_dialog_hide';
export const SAVE_CONTRACT = 'save_contract';
export const ALERT_REMOVE_CONTRACT = 'ALERT_remove_contract';

export const MODAL_BROADCASTER = 'MODAL_BROADCASTER';
export const MODAL_REGION = 'MODAL_REGION';
export const REMOVE_CONTRACT = 'REMOVE_CONTRACT';
export const REMOVE_REGION = 'REMOVE_REGION';
export const REMOVE_GROWTPLANS = 'REMOVE_GROWTHPLANS';
