export const REMOVE_CHANNEL = 'removechannel';
export const SET_CHANNELS = 'SET_CHANNELS';
export const ADD_CHANNEL = 'ADD_CHANNEL';
export const SET_UPLOAD_PROGRESS = 'SET_UPLOAD_PROGRESS';
export const REMOVE_SVA = 'removeSva';
export const SET_SVAS = 'SET_SVAS';
export const ADD_SVA = 'ADD_SVA';
export const UPDATE_SVA = 'UPDATE_SVA';
export const ADD_VOD = 'ADD_VOD';
export const UPDATE_VOD = 'UPDATE_VOD';
export const REMOVE_VOD = 'REMOVE_VOD';
export const SET_BROADCASTER = 'SET_BROADCASTER';
export const LOADING = 'loading_req';
