import React, { useState, useEffect, Fragment } from 'react';
import {
  FormControl,
  Box,
  TextField,
  Button,
  Typography,
  Alert,
  Snackbar,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Tooltip,
  IconButton,
} from '@mui/material';
import { Delete, Person, Password } from '@mui/icons-material';
import { red, indigo, green, grey, blue } from '@mui/material/colors';

import { API } from 'aws-amplify';
import Loading from 'util/loading';

const ExternalUsers = ({ companyId }) => {
  const [users, setUsers] = useState([]); // lista de usuários externos
  const [currentUser, setCurrentUser] = useState(null); // usuário a apagar/resetar senha se clicar no confirmar
  const [loading, setLoading] = useState(false);
  const [showNewUserDialog, setShowNewUserDialog] = useState(false); // diálogo para inserir novo email de usuário
  const [showResetPasswordDialog, setShowResetPasswordDialog] = useState(false);
  const [showDeleteUserDialog, setShowDeleteUserDialog] = useState(false);

  // indicador de erro
  const [errorAlert, setErrorAlert] = useState(null);

  const styles = {
    addButton: {
      marginBottom: '16px',
    },
    userLine: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      padding: 2,
      gap: '10px',
      '&:hover': {
        backgroundColor: '#eeeeff',
      },
    },
    person: {
      color: blue[500],
      backgroundColor: 'white',
      marginLeft: 'auto',
      height: 'fit-content',
    },
    resetPasswordButton: {
      color: 'white',
      backgroundColor: green[500],
      marginLeft: 'auto',
      height: 'fit-content',
    },
    deleteUserButton: {
      color: 'white',
      backgroundColor: red[500],
      marginLeft: 'auto',
      height: 'fit-content',
    },
    dialogTitle: {
      borderBottom: '2px solid black',
      marginBottom: 2,
      minWidth: 400,
    },
  };

  const listExternalUsers = async function () {
    try {
      setLoading(true);
      const apiName = 'lambdasApi';
      const path = '/listUser';
      const options = {
        body: {
          operatorId: companyId,
        },
        headers: {},
      };
      const response = await API.post(apiName, path, options);
      setUsers(response);
    } catch (error) {
      console.log(error);
      setErrorAlert(error.toString());
    } finally {
      setLoading(false);
    }
  };

  const deleteUser = async () => {
    try {
      setLoading(true);
      const apiName = 'lambdasApi';
      const path = '/deleteUser';
      const options = {
        body: {
          username: currentUser,
          operatorId: companyId,
        },
        headers: {},
      };
      const response = await API.post(apiName, path, options);

      // remove da lista local
      const newUserList = users.filter(function (user) {
        return user !== currentUser;
      });
      setUsers(newUserList);
    } catch (error) {
      console.log(error);
      setErrorAlert(error.toString());
    } finally {
      setLoading(false);
      setShowDeleteUserDialog(false);
      setCurrentUser(null);
    }
  };

  useEffect(() => {
    listExternalUsers();
  }, [companyId]);

  const handleDeleteUser = function (aUsername) {
    setCurrentUser(aUsername);
    setShowDeleteUserDialog(true);
  };

  const handleResetUserPassword = function (aUsername) {
    setCurrentUser(aUsername);
    setShowResetPasswordDialog(true);
  };

  const addUser = async function (aEmail) {
    try {
      setLoading(true);
      const apiName = 'lambdasApi';
      const path = '/createUser';
      const options = {
        body: {
          email: aEmail,
          operatorId: companyId,
        },
        headers: {},
      };
      const response = await API.post(apiName, path, options);
      setUsers((users) => [...users, aEmail]);
    } catch (error) {
      console.log(error);
      setErrorAlert(error.toString());
    } finally {
      setLoading(false);
    }
  };

  const resetUserPassword = async function () {
    try {
      setLoading(true);
      const apiName = 'lambdasApi';
      const path = '/resetUserPassword';
      const options = {
        body: {
          username: currentUser,
        },
        headers: {},
      };
      const response = await API.post(apiName, path, options);
    } catch (error) {
      console.log(error);
      setErrorAlert(error.toString());
    } finally {
      setLoading(false);
      setShowResetPasswordDialog(false);
      setCurrentUser(null);
    }
  };

  return (
    <Fragment>
      <Loading loading={loading} />

      {/* Confirmação de delete user */}
      <Dialog
        open={Boolean(showDeleteUserDialog)}
        onClose={() => setShowDeleteUserDialog(false)}
      >
        <DialogTitle sx={styles.dialogTitle}>Remover usuário</DialogTitle>
        <DialogContent>
          Quer realmente remover o usuário {currentUser}?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowDeleteUserDialog(false)} autoFocus>
            Cancelar
          </Button>
          <Button onClick={() => deleteUser()}>Sim</Button>
        </DialogActions>
      </Dialog>

      {/* Confirmação de reset password */}
      <Dialog
        open={Boolean(showResetPasswordDialog)}
        onClose={() => setShowResetPasswordDialog(false)}
      >
        <DialogTitle sx={styles.dialogTitle}>Resetar senha?</DialogTitle>
        <DialogContent>
          <Typography>
            Quer realmente resetar a senha do usuário {currentUser}?
          </Typography>
          <Typography>A nova senha temporária será</Typography>
          <Typography fontFamily="monospace">Abcd1234</Typography>
          <Typography>válida por 7 dias.</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowResetPasswordDialog(false)} autoFocus>
            Cancelar
          </Button>
          <Button onClick={() => resetUserPassword()}>Resetar</Button>
        </DialogActions>
      </Dialog>

      {/* Mensagens de erro */}
      <Snackbar
        open={Boolean(errorAlert)}
        autoHideDuration={1500}
        onClose={() => setErrorAlert(null)}
      >
        <Alert
          onClose={() => setErrorAlert(null)}
          severity="error"
          variant="filled"
          sx={{ width: '100%' }}
        >
          {errorAlert}
        </Alert>
      </Snackbar>

      {/* Criar novo usuário externo */}
      <Dialog
        open={Boolean(showNewUserDialog)}
        onClose={() => setShowNewUserDialog(false)}
        PaperProps={{
          component: 'form',
          onSubmit: (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            const email = formJson.email;
            console.log(email);
            addUser(email);
            setShowNewUserDialog(false);
          },
        }}
      >
        <DialogTitle>Adicionar usuário externo</DialogTitle>
        <DialogContent>
          Usuário para acessar a base de relatórios da empresa.
          <TextField
            autoFocus
            required
            margin="dense"
            id="name"
            name="email"
            label="Endereço de Email válido"
            type="email"
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowNewUserDialog(false)}>Cancelar</Button>
          <Button type="submit">Adicionar</Button>
        </DialogActions>
      </Dialog>

      <Button
        variant="contained"
        type="submit"
        color="primary"
        onClick={() => setShowNewUserDialog(true)}
        sx={styles.addButton}
      >
        <span>Adicionar usuário</span>
      </Button>

      {/* Lista dos usuários existentes */}
      {users.map((username, index) => {
        return (
          <Box key={index} sx={styles.userLine}>
            <FormControl>
              <Person sx={styles.person} />
            </FormControl>

            <FormControl
              sx={{
                flexGrow: 4,
              }}
            >
              <Box>{username}</Box>
            </FormControl>

            <FormControl>
              <Tooltip title="Resetar senha">
                <Box>
                  <IconButton
                    size="small"
                    onClick={() => handleResetUserPassword(username)}
                    edge="end"
                    sx={styles.resetPasswordButton}
                  >
                    <Password />
                  </IconButton>
                </Box>
              </Tooltip>
            </FormControl>

            <FormControl>
              <Tooltip title="Remover usuário">
                <Box>
                  <IconButton
                    size="small"
                    onClick={() => handleDeleteUser(username)}
                    edge="end"
                    sx={styles.deleteUserButton}
                  >
                    <Delete />
                  </IconButton>
                </Box>
              </Tooltip>
            </FormControl>
          </Box>
        );
      })}
    </Fragment>
  );
};

export default ExternalUsers;
