import React from 'react';
import ReportsItem from './item';

const Reports2Page = () => {
  return (
    <div className="app-wrapper">
      <ReportsItem />
    </div>
  );
};
export default Reports2Page;
