export const LOADING = 'LOADING';
export const SET_ALERT = 'SET_ALERT';
export const SET_OPERATORS = 'SET_OPERATORS';
export const SET_CURRENT_OPERATOR = 'SET_CURRENT_OPERATOR';
export const SET_UPLOAD_PROGRESS = 'SET_UPLOAD_PROGRESS';
export const SET_SHEET_WARNINGS = 'SET_SHEET_WARNINGS';
export const SET_BOTTOMNAVVALUE = 'SET_BOTTOMNAVVALUE';
export const SET_SHEETS = 'SET_SHEETS';
export const SET_REFDATE = 'SET_REFDATE';
export const SET_CURRENT_REGION = 'SET_CURRENT_REGION';
export const SET_CURRENT_WARNING = 'SET_CURRENT_WARNING';
export const RECEIVE_MESSAGE = 'RECEIVE_MESSAGE';
export const SET_SUBSCRIPTION = 'SET_SUBSCRIPTION';
export const SET_BATCH_LOG = 'SET_BATCH_LOG';
export const SET_SHOW_LOG_BATCH_UPLOAD = 'SET_SHOW_LOG_BATCH_UPLOAD';
export const SET_DISPONIBLE_REGIONS = 'SET_DISPONIBLE_REGIONS';
export const SET_SELECTED_FILES = 'SET_SELECTED_FILES';
