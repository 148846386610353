import React from 'react';
import ParserItem from './item';
const ParserPage = () => {
  return (
    <div className="app-wrapper">
      <ParserItem />
    </div>
  );
};
export default ParserPage;
