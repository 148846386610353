import React, { useEffect, useState, Fragment } from 'react';

import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Select,
  MenuItem,
  Button,
  Snackbar,
  FormControl,
  Box,
  InputLabel,
  Typography,
} from '@mui/material';

import Alert from '@mui/material/Alert';
import Loading from 'util/loading';

import { Storage } from 'aws-amplify';

import ReportFile from './reportFile';

var JSZip = require('jszip');

function initialDate() {
  let dt = new Date();
  dt.setDate(10);
  dt.setMonth(dt.getMonth() - 1);
  return dt;
}

const styles = {
  topBox: {
    columnGap: 12,
    // '&:hover': {
    //   backgroundColor: '#eaeaea',
    // },
  },
  cards: {
    marginTop: 6,
  },
  botao: {
    margin: 1,
  },
};

const ReportsItem = (props) => {
  const { item } = props;

  const [alert, setAlert] = useState({
    severity: 'info',
    message: '',
    open: false,
  });

  // data selecionada
  const iDate = initialDate();
  const [refDate, setRefDate] = useState(iDate);
  const [downloadProgress, setDownloadProgress] = useState(0);

  // lista de arquivos desse programador, para aquele ano/mes
  const [s3Files, setS3Files] = useState([]);

  const [loading, setLoading] = useState(false);

  // lista os arquivos que devem estar no S3 na pasta
  // public/reports/YYYY/MM/
  async function updateS3Files() {
    const fileList = [];
    try {
      const m = (refDate.getMonth() + 1).toString().padStart(2, '0');
      const y = refDate.getFullYear().toString();
      const key = 'reports/' + y + '/' + m + '/';
      setLoading(true);

      const result = await Storage.list(key, {
        level: 'public',
        pageSize: 800,
      });

      for (const f of result.results) {
        if (f.size > 0) {
          // para não incluir o próprio diretório
          fileList.push(f);
        }
      }
    } catch (error) {
      console.log(error);
      setAlert({
        message: error.toString(),
        severity: 'error',
        open: true,
      });
    } finally {
      setS3Files(fileList.sort());

      setLoading(false);
    }
  }

  // quando a variável refDate é alterada
  useEffect(() => {
    updateS3Files();
  }, [refDate]);

  // quando usuário clica no botão para mudar o mês
  function handleChangeMonth(event) {
    const m = event.target.value;
    const d = new Date(refDate);
    d.setMonth(m);
    setRefDate(d);
  }

  // quando usuário clica no botão para mudar o ano
  function handleChangeYear(event) {
    const y = event.target.value;
    const d = new Date(refDate);
    d.setFullYear(y);
    setRefDate(d);
  }

  // quando usuário clica no botão para fechar o alerta
  function handleCloseAlert() {
    setAlert({
      message: '',
      severity: 'info',
      open: false,
    });
  }

  // retorna os anos a partir de 2020
  function getYearsList() {
    const years = [];
    for (let i = new Date().getFullYear(); i >= 2020; i--) {
      years.push(i);
    }
    return years;
  }

  // quando clica no botão de download

  async function handleDownloadAll() {
    try {
      setLoading(true);
      const zip = new JSZip();

      // calcula o tamanho total para ir mostrando na barra de progresso
      let totalSize = 1; // para evitar divisão por zero
      for (const f of s3Files) {
        totalSize += f.size;
      }
      let partialSize = 0;

      for (const f of s3Files) {
        const result = await Storage.get(f.key, {
          level: 'public',
          expires: 60,
          download: true,
          cacheControl: 'no-cache',
        });
        partialSize += f.size;

        const content = result.Body;
        zip.file(f.key.split('/').at(-1), content);

        const progress = Math.min((partialSize * 100) / totalSize, 100).toFixed(
          2
        );
        setDownloadProgress(progress);
      }

      const zipOfAll = await zip.generateAsync({ type: 'blob' });
      setDownloadProgress(0);

      const url = URL.createObjectURL(zipOfAll);
      let dwnld = document.createElement('a');
      dwnld.setAttribute('href', url);
      dwnld.setAttribute('download', refDate.toLocaleDateString('pt-BR'));
      document.body.appendChild(dwnld);

      const evt = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true,
        clientX: 20,
        /* whatever properties you want to give it */
      });
      dwnld.dispatchEvent(evt);
      document.body.removeChild(dwnld);
    } catch (error) {
      console.log(error);
      setAlert({
        message: error.toString(),
        severity: 'error',
        open: true,
      });
    } finally {
      setLoading(false);
    }
  }

  return (
    <Fragment>
      <Loading loading={Boolean(loading)} />

      <Snackbar
        open={alert.open}
        autoHideDuration={4000}
        onClose={handleCloseAlert}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Alert severity={alert.severity}>{alert.message}</Alert>
      </Snackbar>

      <Card className="shadow border-0">
        <CardContent>
          <Box display="flex" flexDirection="row" sx={styles.topBox}>
            <FormControl className="w-100" variant="standard">
              <InputLabel>Mês</InputLabel>
              <Select
                name="month"
                value={refDate.getMonth()}
                onChange={(e) => handleChangeMonth(e)}
                variant="standard"
              >
                {[...Array(12).keys()].map((item, idx) => (
                  <MenuItem key={idx} value={idx}>
                    {(idx + 1).toString().padStart(2, '0')}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className="w-100" variant="standard">
              <InputLabel>Ano</InputLabel>
              <Select
                name="year"
                value={refDate.getFullYear()}
                onChange={(e) => handleChangeYear(e)}
                variant="standard"
              >
                {getYearsList().map((item, idx) => (
                  <MenuItem key={idx} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </CardContent>
      </Card>
      <Card sx={styles.cards}>
        <CardHeader title="Lista de relatórios" />
        <CardContent>
          {s3Files.map((file, idx) => (
            <ReportFile key={idx} file={file} onChange={updateS3Files} />
          ))}
        </CardContent>
        <CardActions>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              alignItems: 'center',
              margin: 1,
            }}
          >
            <Button
              variant="contained"
              sx={styles.botao}
              onClick={() => handleDownloadAll()}
            >
              Download de todos os relatórios
            </Button>

            {Boolean(downloadProgress > 0) && (
              <Typography>{downloadProgress} %</Typography>
            )}
          </Box>
        </CardActions>
      </Card>
    </Fragment>
  );
};

export default ReportsItem;
