import * as actions from './actions';

const BroadcasterReducer = (prevState, action) => {
  switch (action.type) {
    case actions.REMOVE_CHANNEL:
      {
        const channel = action.payload;
        let index = -1;
        prevState.broadcaster.channels.items.forEach(function (c, i) {
          if (c.id === channel.id) {
            index = i;
          }
        });
        if (index !== -1) {
          prevState.broadcaster.channels.items.splice(index, 1);
        }
        return {
          ...prevState,
        };
      }
      break;

    case actions.SET_CHANNELS:
      {
        prevState.broadcaster.channels.items = action.payload;
        return {
          ...prevState,
        };
      }
      break;

    case actions.ADD_CHANNEL:
      {
        prevState.broadcaster.channels.items.push(action.payload);
        return {
          ...prevState,
        };
      }
      break;

    case actions.REMOVE_SVA:
      {
        const sva = action.payload;
        let index = -1;
        prevState.broadcaster.svas.items.forEach(function (c, i) {
          if (c.id === sva.id) {
            index = i;
          }
        });
        if (index !== -1) {
          prevState.broadcaster.svas.items.splice(index, 1);
        }
        return {
          ...prevState,
        };
      }
      break;

    case actions.SET_SVAS:
      {
        prevState.broadcaster.svas.items = action.payload;
        return {
          ...prevState,
        };
      }
      break;

    case actions.ADD_SVA:
      {
        if (typeof prevState.broadcaster.svas === 'undefined') {
          prevState.broadcaster.svas = {
            items: [],
          };
        }
        prevState.broadcaster.svas.items.push(action.payload);
        return {
          ...prevState,
        };
      }
      break;

    case actions.UPDATE_SVA:
      {
        const sva = action.payload;

        prevState.broadcaster.svas.items.map((item, idx) => {
          if (item.id == sva.id) {
            prevState.broadcaster.svas.items[idx] = sva;
          }
        });
        return {
          ...prevState,
        };
      }
      break;

    case actions.ADD_VOD:
      {
        if (typeof prevState.broadcaster.vod === 'undefined') {
          prevState.broadcaster.vod = {
            items: [],
          };
        }
        prevState.broadcaster.vod.items.push(action.payload);
        return {
          ...prevState,
        };
      }
      break;

    case actions.REMOVE_VOD:
      {
        if (typeof prevState.broadcaster.vod === 'undefined') {
          prevState.broadcaster.vod = {
            items: [],
          };
        }

        const vod = action.payload;
        let index = -1;
        prevState.broadcaster.vod.items.forEach(function (c, i) {
          if (c.id === vod.id) {
            index = i;
          }
        });
        if (index !== -1) {
          prevState.broadcaster.vod.items.splice(index, 1);
        }
        return {
          ...prevState,
        };
      }
      break;

    case actions.UPDATE_VOD:
      {
        const vod = action.payload;

        if (typeof prevState.broadcaster.vod === 'undefined') {
          prevState.broadcaster.vod = {
            items: [],
          };
        }

        prevState.broadcaster.vod.items.map((item, idx) => {
          if (item.id == vod.id) {
            prevState.broadcaster.vod.items[idx] = vod;
          }
        });
        return {
          ...prevState,
        };
      }
      break;

    case actions.SET_BROADCASTER:
      {
        prevState.broadcaster = action.payload;
        return {
          ...prevState,
        };
      }
      break;

    case actions.LOADING:
      {
        const l = prevState.loading + (action.payload ? 1 : -1);
        return {
          ...prevState,
          loading: l,
        };
      }
      break;

    case actions.SET_UPLOAD_PROGRESS:
      return {
        ...prevState,
        uploadProgress: action.payload,
      };

    default:
      return {
        ...prevState,
      };
  }
};
export default BroadcasterReducer;
