import { API, graphqlOperation } from 'aws-amplify';
import * as custom from './queries';
import * as queries from '../queries';

export const listAll = (query, items = [], nextToken = null) =>
  new Promise(function (resolve, reject) {
    try {
      let variables = {};
      if (nextToken) {
        variables.nextToken = nextToken;
      }
      API.graphql(graphqlOperation(query, variables))
        .then(function (result) {
          if (result.errors) {
            console.log(result.errors);
            reject(result.errors);
          } else {
            const key = Object.keys(result.data).find((k) =>
              k.includes('list')
            );
            const res = result.data[key]; // res = { items: [], nextToken: '' }
            items.push(...res.items);

            if (!res.hasOwnProperty('nextToken') || res.nextToken == null) {
              resolve(items);
            } else {
              // eslint-disable-next-line no-param-reassign
              //              variables.nextToken = res.nextToken;
              listAll(query, items, res.nextToken)
                .then(function (result) {
                  if (result.errors) {
                    reject(result.errors);
                  } else {
                    resolve(result);
                  }
                })
                .catch(function (error) {
                  console.log(error);
                  reject(error);
                });
            }
          }
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });

export const getBroadcaster = async function (aBroadcasterId) {
  const operation = custom.getBroadcaster;
  let nextToken = null;
  let broadcaster = null;

  do {
    const result = (
      await API.graphql(
        graphqlOperation(operation, {
          id: aBroadcasterId,
          nextToken: nextToken,
        })
      )
    ).data.getBroadcaster;

    if (broadcaster === null) {
      broadcaster = Object.assign({}, result);
    } else {
      broadcaster.channels.items = broadcaster.channels.items.concat(
        result.channels.items
      );
      broadcaster.svas.items = broadcaster.svas.items.concat(result.svas.items);
    }

    nextToken = result.channels.nextToken;
  } while (nextToken !== null);

  return broadcaster;
};

const getOperatorWithRegions = (id, regions = [], nextTokenRegions = null) =>
  new Promise(function (resolve, reject) {
    let operation = custom.getOperator;
    let operationInput = {
      id: id,
    };

    if (nextTokenRegions != null) {
      operationInput['nextTokenRegions'] = nextTokenRegions;
    }

    try {
      API.graphql(graphqlOperation(operation, operationInput))
        .then(function (result) {
          if (result.errors) {
            reject(result.errors);
          } else {
            regions = regions.concat(result.data.getOperator.regions.items);

            let nxtToken = null;
            if (result.data.getOperator.regions.hasOwnProperty('nextToken')) {
              nxtToken = result.data.getOperator.regions.nextToken;
            }

            if (nxtToken !== null) {
              getOperatorWithRegions(id, regions, nxtToken)
                .then(function (result) {
                  if (result.errors) {
                    console.log(result.errors);
                    reject(result.errors);
                  } else {
                    resolve(result);
                  }
                })
                .catch(function (error) {
                  console.log(error);
                  reject(error);
                });
            } else {
              // no more tokens
              let br = result.data.getOperator;
              br.regions.items = [...regions];
              resolve(br);
            }
          }
        })
        .catch(function (errors) {
          console.log(errors);
          reject(errors);
        });
    } catch (errors) {
      console.log(errors);
      reject(errors);
    }
  });

const getOperatorWithContracts = (
  id,
  contracts = [],
  nextTokenContracts = null
) =>
  new Promise(function (resolve, reject) {
    let operation = custom.getOperator;
    let operationInput = {
      id: id,
    };

    if (nextTokenContracts !== null) {
      operationInput['nextTokenContracts'] = nextTokenContracts;
    }

    try {
      API.graphql(graphqlOperation(operation, operationInput))
        .then(function (result) {
          if (result.errors) {
            reject(result.errors);
          } else {
            contracts = contracts.concat(
              result.data.getOperator.contracts.items
            );

            let nxtToken = null;
            if (result.data.getOperator.contracts.hasOwnProperty('nextToken')) {
              nxtToken = result.data.getOperator.contracts.nextToken;
            }

            if (nxtToken != null) {
              getOperatorWithContracts(id, contracts, nxtToken)
                .then(function (result) {
                  if (result.errors) {
                    console.log(result.errors);
                    reject(result.errors);
                  } else {
                    resolve(result);
                  }
                })
                .catch(function (error) {
                  console.log(error);
                  reject(error);
                });
            } else {
              // no more tokens
              let br = result.data.getOperator;
              br.contracts.items = [...contracts];
              resolve(br);
            }
          }
        })
        .catch(function (errors) {
          console.log(errors);
          reject(errors);
        });
    } catch (errors) {
      console.log(errors);
      reject(errors);
    }
  });

export const getOperator = async function (id) {
  let operator = await getOperatorWithRegions(id);
  if (operator.contracts.nextToken) {
    let opCont = await getOperatorWithContracts(id).catch((error) =>
      Promise.reject(error)
    );
    operator.contracts.items = Object.assign({}, opCont.contracts.items);
  }
  let promises = [];
  operator.contracts.items.forEach(function (contract) {
    promises.push(getContract(contract.id));
  });
  operator.contracts.items = await Promise.all(promises).catch((error) =>
    Promise.reject(error)
  );
  return Promise.resolve(operator);
};

export const getContract = async function (aContractId) {
  const operation = custom.getContract;
  let nextToken = null;
  let contract = null;

  do {
    const result = (
      await API.graphql(
        graphqlOperation(operation, {
          id: aContractId,
          nextToken: nextToken,
        })
      )
    ).data.getContract;

    if (contract === null) {
      contract = Object.assign({}, result);
    } else {
      contract.lineup.items = contract.lineup.items.concat(result.lineup.items);
    }

    nextToken = result.lineup.nextToken;
  } while (nextToken !== null);

  return contract;
};

export const getCities = (state, cities = [], nextToken = null) =>
  new Promise(function (resolve, reject) {
    let operation = queries.municipalityByState;
    let operationInput = {
      state: state,
      limit: 1000,
    };

    if (nextToken != null) {
      operationInput['nextToken'] = nextToken;
    }

    try {
      API.graphql(graphqlOperation(operation, operationInput))
        .then(function (result) {
          if (result.errors) {
            reject(result.errors);
          } else {
            cities = cities.concat(result.data.municipalityByState.items);

            let nxtToken = result.data.municipalityByState.nextToken;

            if (nxtToken != null) {
              getCities(state, cities, nxtToken)
                .then(function (result) {
                  if (result.errors) {
                    console.log(result.errors);
                    reject(result.errors);
                  } else {
                    resolve(result);
                  }
                })
                .catch(function (error) {
                  console.log(error);
                  reject(error);
                });
            } else {
              // no more tokens
              let br = result.data.municipalityByState;
              br.items = [...cities];
              resolve(br);
            }
          }
        })
        .catch(function (errors) {
          console.log(errors);
          reject(errors);
        });
    } catch (errors) {
      console.log(errors);
      reject(errors);
    }
  });
